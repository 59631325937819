<template>
  <div>
    <!-- Hamburger menu button -->
    <!--
      Toggle "js-hamburger-open" class based on menu state
    -->
    <button 
      @click="mmenuShow = !mmenuShow"
      :class="{ 'js-hamburger-open': mmenuShow }" 
      class='relative z-50 w-6 h-5 transition duration-500 ease-in-out transform rotate-0 cursor-pointer group focus:outline-none' 
    >
      <span class='absolute top-0 left-0 block w-full h-1 transition duration-200 ease-in-out transform rotate-0 bg-purple-900 rounded-full opacity-100 group-hover:bg-purple-600'></span>
      <span class='absolute left-0 block w-full h-1 transition duration-200 ease-in-out transform rotate-0 bg-purple-900 rounded-full opacity-100 top-2 group-hover:bg-purple-600'></span>
      <span class='absolute left-0 block w-full h-1 transition duration-200 ease-in-out transform rotate-0 bg-purple-900 rounded-full opacity-100 top-2 group-hover:bg-purple-600'></span>
      <span class='absolute left-0 block w-full h-1 transition duration-200 ease-in-out transform rotate-0 bg-purple-900 rounded-full opacity-100 top-4 group-hover:bg-purple-600'></span>
    </button>

    <!-- Mobile menu -->
    <!--
      Toggle "js-mobileNav-open" class based on menu state
    -->
    <transition name="mmenu">
      <div 
        v-show="mmenuShow"
        style="display: none;"
        class='absolute top-0 left-0 z-40 w-screen px-4 py-16 overflow-y-scroll bg-gradient-to-tr from-purple-600 to-purple-600 sm:px-8'  
      >
        <div class='flex flex-col items-center justify-center w-full h-full'>

          <!-- Site links -->
          <div class='flex flex-col items-center w-full mx-auto space-y-6 justify-evenly'>
            <router-link to="/" exact>
              <div class="relative p-0.5 group">
                <span class="relative z-10 text-2xl font-medium duration-300 ease-in-out text-purple-50 group-hover:text-white">Home</span>
                <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
              </div>
            </router-link>
            <router-link to="/about">
              <div class="relative p-0.5 group">
                <span class="relative z-10 text-2xl font-medium duration-300 ease-in-out text-purple-50 group-hover:text-white">About</span>
                <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
              </div>
            </router-link>

            <router-link to="/lesson">
              <div class="relative p-0.5 group">
                <span class="relative z-10 text-2xl font-medium duration-300 ease-in-out text-purple-50 group-hover:text-white">Lessons</span>
                <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
              </div>
            </router-link>

            <router-link to="/community">
              <div class="relative p-0.5 group">
                <span class="relative z-10 text-2xl font-medium duration-300 ease-in-out text-purple-50 group-hover:text-white">Community</span>
                <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
              </div>
            </router-link>

            <router-link to="/contact">
              <div class="relative p-0.5 group">
                <span class="relative z-10 text-2xl font-medium duration-300 ease-in-out text-purple-50 group-hover:text-white">Contact</span>
                <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
              </div>
            </router-link>

            <!-- CTA button -->
            <a class="relative inline-flex items-center justify-center px-8 py-4 text-lg font-semibold leading-normal text-center text-purple-900 duration-300 ease-in-out bg-yellow-400 rounded-full" href="#">
              Enroll today
            </a>

          </div>

          <hr class='w-full my-8 border-purple-200 sm:my-10 border-opacity-30'>

          <!-- Programs links -->
          <div class='w-full max-w-md mx-auto'>
            <p class='text-lg font-semibold tracking-wider text-center text-purple-200 uppercase sm:text-left'>
              Programs
            </p>
            <div class='grid gap-4 mt-4 justify-items-center sm:justify-items-start sm:grid-cols-2 sm:gap-x-8'>
              <router-link to="/program">
                <div class="relative p-0.5 group">
                  <span class="relative z-10 text-xl font-medium duration-300 ease-in-out text-purple-50 group-hover:text-white">English Conversation</span>
                  <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
                </div>
              </router-link>
              <router-link to="/program" class='sm:justify-self-end'>
                <div class="relative p-0.5 group">
                  <span class="relative z-10 text-xl font-medium duration-300 ease-in-out text-purple-50 group-hover:text-white">IELTS Preparation</span>
                  <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "MobileMenu",
  data() {
    return {
      mmenuShow: false,
    }
  }
}
</script>

<style scoped>
.mmenu-enter-active,
.mmenu-leave-active {
  transition: opacity 0.2s, margin 0.2s;
}

.mmenu-enter,
.mmenu-leave-to {
  opacity: 0;
  --tw-translate-y: -100%;
  transform: var(--tw-transform);
}
</style>