<template>
  <div>
    <!-- Hero -->
    <CommunityHeroPartial />
    <!-- Events -->
    <EventsPartial />
    <!-- Newsletter -->
    <NewsletterPartial />
    <!-- Height for CtA -->
    <div class='bg-white h-40 sm:h-48'></div>
  </div>
</template>

<script>
import CommunityHeroPartial from './partials/community/CommunityHero.vue'
import EventsPartial from './partials/community/Events.vue'
import NewsletterPartial from './partials/community/Newsletter.vue'

export default {
   name: 'CommunityView',
   components: { 
     CommunityHeroPartial,
      EventsPartial,
      NewsletterPartial 
   },
}
</script>