<template>
  <div 
    :class="cardStyleExtras"
    class="w-full px-6 py-10 rounded-xl lg:px-5 xl:px-10"
  >
    <div class="relative">
      <div class="relative inline-block w-full text-left">
        <h3 class="relative text-xl font-bold tracking-normal text-purple-900">
          {{ item.name }}
        </h3>
        <p>{{ item.description }}</p>
        <div class='mt-2'>
          <h2 class="text-purple-900 h1">
            {{ currencySymbol(price.currency) }}{{ to2DecimalPlaces(price.unit_amount) }}
          </h2>
          <div class='mt-3'>
            <div class="inline-block h-6 px-3 text-sm font-medium leading-6 text-purple-700 align-top bg-purple-200 -rotate-1 rounded-xl">
              <!-- {{ price.nickname }} --> xxx
            </div>
          </div>
          <p class="block w-full mt-6 font-medium text-purple-900">
            M-F from 8 AM to 3:00 PM
          </p>
        </div>
        <!-- Features -->
        <ul class='mt-4 space-y-2 text-base'>
          <li class='flex items-center'>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <span class="ml-2 text-purple-800">
              Nulla quis lorem ut libero
            </span>
          </li>
          <li class='flex items-center'>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <span class="ml-2 text-purple-800">
              Lorem ipsum dolor sit
            </span>
          </li>
        </ul>
        <a
          @click="addToCart(item, price)"
          href='#'
          class="mt-6 font-medium text-white bg-purple-600 btn-sm hover:bg-purple-500 group"
        >Add to cart
          <!-- Tablericon name: arrow-narrow-right -->
          <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <line x1="5" y1="12" x2="19" y2="12" />
            <line x1="15" y1="16" x2="19" y2="12" />
            <line x1="15" y1="8" x2="19" y2="12" />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { isEvenNumber } from '@/utils/NumberUtil'
import { currencySymbol } from '@/data/currencies'
import PriceMixin from '@/mixins/Price'

export default {
  name: 'ProductItem',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    index: Number 
  },
  methods: {
    isEvenNumber,
    currencySymbol,
    /* Adds an item to the shopping cart */ 
    addToCart(item, priceObj) {
      this.$store.commit('ADDITEM_TOCART', {
        productId: item.id,
        quantity: this.quantity,
        unitPrice: priceObj.unit_amount,
        price: priceObj,
        shipping: 0
      })
    },
    /* Sets the card's bg color */
    cardBgColor() {
      return this.bgcolors[this.index].cardBgColor
    },
    /* Concatenates some style if the current item is part of an even (numbered) card */ 
    evenCard() {
      return isEvenNumber(this.index + 1) ? "lg:-translate-y-10" : ""
    },
    /* Sets the price for the current currency */
    priceByCurrency() {
      let itemPrices = this.item.prices
      let length = itemPrices.length
      for (let i = 0; i < length; i++ ) {
        if (itemPrices[i].currency == this.currentCurrency) {
          this.price = itemPrices[i]
          break
        }
      }      
    }
  },
  computed: {
    /* Return the extra styles of the card */ 
    cardStyleExtras() {
      const evenCardStr = this.evenCard()
      return evenCardStr.concat(' ', this.cardBgColor())
    },
    /* Returns the currency currently set in the store */
    currentCurrency() {
      return this.$store.state.currency;
    },
  },
  
  created() {
    // this.itemPrices = this.item.prices

    console.log(this.item)

    this.priceByCurrency()
  },

  data() {
    return {
      quantity: 1,  // For now, the cart only allows 1 item of each program
      price: null,
      bgcolors: [
        { "cardBgColor": "bg-purple-25" },
        { "cardBgColor": "bg-yellow-200" },
        { "cardBgColor": "bg-rose-50" },
        { "cardBgColor": "bg-blue-50" }
      ]
    };
  },
  mixins: [
    PriceMixin
  ]
}
</script>