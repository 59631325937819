<template>
  <section class='relative overflow-hidden'>

    <!-- Purple background to fill in right gap -->
    <div class='absolute inset-y-0 right-0 w-full max-w-screen-xl ml-auto bg-purple-600 rounded-l-5xl'></div>
    
    <!-- Background dots decorations -->
    <img src='../../../assets/img/dots-chaos.svg' class='absolute bottom-0 right-0 z-10 hidden h-auto transform 2xl:block w-80' alt=''>
    <img src='../../../assets/img/dots-strip.svg' class='absolute z-10 hidden h-auto transform 2xl:block top-1 right-20 w-36' alt=''>

    <!-- Main Section-->
    <div class='relative w-full px-4 py-16 bg-purple-600 2xl:max-w-screen-xl 2xl:mx-auto 2xl:rounded-l-5xl sm:px-6 lg:pr-0 sm:py-24 lg:py-0'>
      <div class='relative grid gap-12 lg:grid-cols-2 2xl:gap-4 lg:pr-10 2xl:pr-0'>
        <div class='relative grid order-2 w-full max-w-2xl grid-cols-2 gap-3 mx-auto lg:order-1 sm:gap-6 lg:py-32 lg:max-w-none'>

          <img src='../../../assets/img/dots-purple-mess.svg' class='absolute hidden transform lg:block top-12 -right-16 2xl:right-0 ' alt=''>
          <img src='../../../assets/img/dots-grid.svg' class='absolute top-0 hidden h-auto transform translate-y-12 right-2 w-80' alt=''>
          <div class='col-span-2 transform aspect-w-3 aspect-h-4 2xl:-translate-x-16'>
            <img src='../../../assets/img/program-description-01.jpg' class='object-cover w-full h-full rounded-3xl 2xl:rounded-4xl' alt='Program description 01'>
          </div>
          <div class='transform 2xl:-translate-x-16'>
            <div class='aspect-w-1 aspect-h-1'>
              <img src='../../../assets/img/program-description-02.jpg' class='object-cover w-full h-full rounded-3xl 2xl:rounded-4xl' alt='Program description 02'>
            </div>
          </div>
          <div class='transform 2xl:-translate-x-16'>
            <div class='aspect-w-1 aspect-h-1'>
              <img src='../../../assets/img/program-description-03.jpg' class='object-cover w-full h-full rounded-3xl 2xl:rounded-4xl' alt='Program description 03'>
            </div>
          </div>
        </div>

        <div class='flex flex-col justify-center order-1 lg:order-2 lg:py-36'>
          <div class='relative z-20 mx-auto prose prose-lg prose-light sm:prose-xl'>
            <h2>
              Our unique approach to education
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisl pretium fusce id velit ut. Id porta nibh venenatis cras sed felis eget velit. Ut morbi tincidunt augue interdum velit. Ipsum faucibus vitae aliquet nec ullamcorper sit amet. Viverra orci sagittis eu volutpat odio facilisis mauris. Diam quis enim lobortis scelerisque fermentum. Viverra mauris in aliquam sem fringilla. 
            </p>
            <h3>What they will learn</h3>
            <ul>
              <li>Vivamus suscipit tortor eget felis porttitor volutpat.</li>
              <li>Nulla porttitor accumsan tincidunt.</li>
              <li>Vivamus magna justo, lacinia eget consectetur sed.</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Ut enim ad minim veniam,</li>
              </ul>
            <p>
              Elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl. Viverra vitae congue eu consequat ac felis donec et odio. Euismod nisi porta lorem mollis aliquam ut porttitor. Sed nisi lacus sed viverra tellus. Augue lacus viverra vitae congue eu consequat ac felis donec. Elementum pulvinar etiam non quam lacus. Ut venenatis tellus in metus vulputate. Ultrices dui sapien eget mi proin sed libero enim. Id velit ut tortor pretium viverra suspendisse.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DescriptionPartial"
}
</script>