<template>
  <section class='relative py-16 bg-purple-600 sm:py-20 lg:py-24'>
  
    <!-- Background decoration -->
    <img src='../../../assets/img/dots-chaos.svg' class='absolute bottom-0 right-0 h-auto transform w-72' alt=''>
    
    <!-- Content container -->
    <div class='relative grid gap-12 px-4 mx-auto lg:grid-cols-12 lg:max-w-screen-xl sm:px-6 lg:px-8'>

      <div class='flex flex-col items-center justify-center max-w-xl mx-auto lg:col-span-5 lg:items-start lg:mx-0'>
        <h2 class='text-center text-white h2 lg:text-left'>
          Latest from the newsletter
        </h2>
        <div class='relative'>
          <p class="max-w-lg mt-3 text-lg leading-relaxed text-center lg:text-xl lg:mt-4 text-purple-50 lg:text-left">
            Don't miss out on your child's education. We send information on important events and dates to our parents once a month. Sign up below!
          </p>

          <!-- Arrow to newsletter -->
          <img src='../../../assets/img/arrow-down.svg' class='absolute hidden sm:block lg:hidden 2xl:block -left-14 md:-left-24 top-1/2 w-14 md:w-20' alt=''>
          
        </div>

        <!-- Newsletter signup section -->
        <div class="flex items-center mt-8">
          <div class='relative w-full max-w-xl rounded-full h-14'>

            <!-- Newsletter signup form -->
            <form>
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">

                <!-- Heroicon name: solid/mail -->
                <svg class='w-6 h-6 text-purple-500' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                
              </div> 

              <input type="email" class="text-purple-700 h-14 w-full pl-12 pr-24 py-3.5 border-0 bg-purple-25 border-transparent rounded-full leading-5 duration-300 placeholder-purple-700 focus:bg-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-cool-indigo-200" required placeholder="Signup to our parent newsletter" autocomplete="email"/>

              <button type='submit' class='inline-flex items-center h-11 absolute right-1.5 top-1.5 outline-none bg-yellow-400 text-purple-900 hover:bg-yellow-600 py-2 px-4 md:px-6 rounded-full text-sm sm:font-medium hover:bg-cool-indigo-700 duration-300 ease-in-out focus:outline-none'>
                Subscribe
              </button>

            </form>

            <p class='pl-2 mt-2.5 text-sm text-purple-50 text-center lg:text-left'>Your privacy is important to us. We promise not to send you spam!</p>
          </div>
        </div>

      </div>

      <!-- Latest newsletters -->
      <div class="grid max-w-3xl gap-6 mx-auto mt-12 lg:mt-0 lg:mx-0 lg:pl-12 lg:col-span-7 sm:grid-cols-2 sm:gap-5 md:gap-8">

        <!-- Issue 1 -->
        <a href="#" class="flex flex-col p-6 transition duration-300 ease-in-out border border-purple-500 rounded-md sm:p-7 group hover:scale-105 hover:shadow-lg hover:bg-purple-500 hover:border-transparent">
          <div class="flex-1">
            <h3 class="text-lg font-bold text-white">
              July
            </h3>
            <p class="mt-3 text-purple-50">
              Praesent sapien massa, convallis a pellentesque nec egestas.
            </p>
          </div>
          <div>
            <span class="mt-6 rounded-full text-sm font-medium text-purple-600 py-1.5 px-5 inline-flex justify-center items-center bg-purple-100 duration-300 ease-in-out group-hover:text-white group-hover:bg-white/30">
              Read newsletter 

              <!-- Heroicon name: chevron-right -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 ml-2 group-hover:animate-horizontal-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>
              
            </span>
          </div>
        </a>

        <!-- Issue 2 -->
        <a href="#" class="flex flex-col p-6 transition duration-300 ease-in-out bg-purple-600 border border-purple-500 rounded-md sm:p-7 group hover:scale-105 hover:shadow-lg hover:bg-purple-500 hover:border-transparent">
          <div class="flex-1">
            <h3 class="text-lg font-bold text-white">
              June
            </h3>
            <p class="mt-3 text-purple-50">
              Praesent sapien massa, convallis a pellentesque nec egestas.
            </p>
          </div>

          <div>
            <span class="mt-6 rounded-full text-sm font-medium text-purple-600 py-1.5 px-5 inline-flex justify-center items-center bg-purple-100 duration-300 ease-in-out group-hover:text-white group-hover:bg-white/30">
              Read newsletter 

              <!-- Heroicon name: chevron-right -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 ml-2 group-hover:animate-horizontal-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>

            </span>
          </div>
        </a>

        <!-- Issue 3 -->
        <a href="#" class="flex flex-col p-6 transition duration-300 ease-in-out bg-purple-600 border border-purple-500 rounded-md sm:p-7 group hover:scale-105 hover:shadow-lg hover:bg-purple-500 hover:border-transparent">
          <div class="flex-1">
            <h3 class="text-lg font-bold text-white">
              May
            </h3>
            <p class="mt-3 text-purple-50">
              Praesent sapien massa, convallis a pellentesque nec egestas.
            </p>
          </div>

          <div>
            <span class="mt-6 rounded-full text-sm font-medium text-purple-600 py-1.5 px-5 inline-flex justify-center items-center bg-purple-100 duration-300 ease-in-out group-hover:text-white group-hover:bg-white/30">
              Read newsletter

              <!-- Heroicon name: chevron-right -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 ml-2 group-hover:animate-horizontal-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>

            </span>
          </div>
        </a>

        <!-- Issue 4 -->
        <a href="#" class="flex flex-col p-6 transition duration-300 ease-in-out bg-purple-600 border border-purple-500 rounded-md sm:p-7 group hover:scale-105 hover:shadow-lg hover:bg-purple-500 hover:border-transparent">
          <div class="flex-1">
            <h3 class="text-lg font-bold text-white">
              April
            </h3>
            <p class="mt-3 text-purple-50">
              Praesent sapien massa, convallis a pellentesque nec egestas.
            </p>
          </div>
          
          <div>
            <span class="mt-6 rounded-full text-sm font-medium text-purple-600 py-1.5 px-5 inline-flex justify-center items-center bg-purple-100 duration-300 ease-in-out group-hover:text-white group-hover:bg-white/30">
              Read newsletter 

              <!-- Heroicon name: chevron-right -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 ml-2 group-hover:animate-horizontal-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>

            </span>
          </div>
        </a>

      </div>

    </div>

  </section>
</template>

<script>
export default {
  name: 'NewsletterPartial'
}
</script>