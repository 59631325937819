<template>
  <section class='pt-20 bg-yellow-100 sm:pt-28'>
    <!-- Container -->
    <div class='px-4 mx-auto lg:max-w-screen-lg sm:px-6 lg:px-8'>
      <!-- Section header title and subtext  -->
      <div class='max-w-2xl'>
        <h2 class='text-purple-900 h2'>
          Frequently asked questions
        </h2>
        <p class='max-w-2xl mt-4 text-xl leading-relaxed text-purple-800 lg:text-left'>
          Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
        </p>
      </div>
      <!-- FAQ -->
      <accordion>
        <accordion-item>
          <template slot="accordion-trigger">
            <h3>What are your hours and schedules?</h3>
          </template>
          <template slot="accordion-content">
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
          </template>
        </accordion-item>
        <accordion-item>
          <template slot="accordion-trigger">
            <h3>What is your Discipline Policy?</h3>
          </template>
          <template slot="accordion-content">
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
          </template>
        </accordion-item>
        <accordion-item>
          <template slot="accordion-trigger">
            <h3>What is your children-to-teacher ratio?</h3>
          </template>
          <template slot="accordion-content">
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
          </template>
        </accordion-item>
      </accordion>
    </div>
  </section>
</template>

<script>
import Accordion from '../../../components/accordion/Accordion.vue'
import AccordionItem from '../../../components/accordion/AccordionItem.vue'

export default {
  name: 'FaqPartial',
  components: { 
    Accordion,
    AccordionItem 
  },  
}
</script>