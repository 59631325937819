<template>
  <section class='overflow-hidden bg-yellow-100 pb-4 px-4 sm:px-6 lg:px-8'>
    <!-- Container -->
    <div class='max-w-screen-xl mx-auto'>

      <!-- Centered content with feature list -->
      <div class="relative">

        <!-- Block title and subtext -->
        <h2 class="max-w-4xl mx-auto text-center text-purple-900 h2">
          Providing the best possible start to your education with English 
        </h2>
        <p class="max-w-3xl mx-auto mt-4 text-xl leading-relaxed text-center text-purple-800">
          English is the most used and spoken language around the world. 
          English is the language used in business, science and technology. 
          Learning English will increase your chances of getting that dream job, participating in discussions, 
          and improve your networking skills with English speakers all over the world. Learning English will help 
          you increase your educational opportunities at home and abroad.
        </p>

        <!-- Feature list -->
        <div class="max-w-3xl mx-auto mt-12">
          <ul class="flex flex-wrap items-center justify-center -mx-3 -my-2 text-lg text-purple-800">
            <li class="flex items-center mx-3 my-2">
              <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
              <span>Personalized</span>
            </li>
            <li class="flex items-center mx-3 my-2">
              <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
              <span>Small classes</span>
            </li>
            <li class="flex items-center mx-3 my-2">
              <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
              <span>Private classes</span>
            </li>
            <li class="flex items-center mx-3 my-2">
              <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
              <span>Coporate groups</span>
            </li>
            <li class="flex items-center mx-3 my-2">
              <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
              <span>Hands-on approach</span>
            </li>
            <li class="flex items-center mx-3 my-2">
              <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
              <span>Diverse students from beginner to advanced</span>
            </li>
            <li class="flex items-center mx-3 my-2">
              <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
              <span>Kindergartedn to PHD candidates</span>
            </li>
            <li class="flex items-center mx-3 my-2">
              <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
              <span>Cambridge IELTS Specialist</span>
            </li>
            <li class="flex items-center mx-3 my-2">
              <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
              <span>Cambridge CELTA Certified</span>
            </li>
          </ul>
        </div>

      </div>

      <!-- First alternating block -->
      <div class='max-w-xl mx-auto mt-16 lg:max-w-none sm:mt-20 lg:mt-24 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24'>

        <!-- Block images-->
        <div class='relative lg:col-span-6'>
          <div class='relative sm:pl-36 lg:pl-20 xl:pl-32'>
            <div class="aspect-w-3 aspect-h-4 rounded-2xl">
              <img class="object-cover rounded-2xl" src="https://source.unsplash.com/Ba6IlmAzl-k/900x1600" alt="Home blocks 01">
            </div>
            <div class="absolute bottom-0 left-0 hidden sm:block sm:translate-y-1/3 sm:w-72 sm:h-72 lg:w-64 lg:h-64 xl:w-72 xl:h-72 2xl:w-80 2xl:h-80 rounded-3xl">
              <img class="object-cover w-full h-full rounded-3xl" src="https://source.unsplash.com/qDY9ahp0Mto/600x600" alt="Home blocks 02">
            </div>
          </div>
        </div>

        <!-- Block text content-->
        <div class='flex flex-col justify-center mt-16 sm:mt-44 lg:mt-0 lg:col-span-6'>
          <div>
            <badge-intro-title title="A learner-centred approach" />
          </div>
          <h3 class='mt-4 text-purple-900 sm:mt-5 h3'>
            The goal is to ensure your success in life
          </h3>
          <p class='max-w-2xl mt-3 text-lg leading-loose text-purple-800'>
            Ms Shamila focuses on a learner-centred approach in all of her classes. 
            Creating opportunities for students to teach each other, answer their peer's questions, 
            and present the results of their works. This approach helps her students develop skills 
            that will better equip them for their future in education as well as their professional careers.
          </p>
          <div class='mt-6'>
            <button-more title="Learn more" />
          </div>
        </div>
      </div>

      <!-- Second alternating block -->
      <div class='max-w-xl mx-auto mt-16 sm:mt-44 lg:mt-56 xl:mt-60 2xl:mt-64 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24 lg:max-w-none'>

        <!-- Block images -->
        <div class='relative lg:order-2 lg:col-span-6'>
          <div class='relative sm:pr-36 lg:pr-20 xl:pr-32'>
            <div class="aspect-w-3 aspect-h-4 rounded-3xl">
              <img class="object-cover rounded-3xl" src="https://source.unsplash.com/XZkk5xT8Xrk/1600x900" alt="Home blocks 03">
            </div>
            <div class="absolute top-0 right-0 hidden sm:block sm:-translate-y-1/3 sm:w-72 sm:h-72 lg:w-64 lg:h-64 xl:w-72 xl:h-72 2xl:w-80 2xl:h-80 rounded-3xl">
              <img class="object-cover w-full h-full rounded-3xl" src="https://source.unsplash.com/_UeY8aTI6d0/400x400" alt="Home blocks 04">
            </div>
          </div>
        </div>

        <!-- Block text content -->
        <div class='flex flex-col justify-center mt-16 sm:mt-20 lg:mt-0 lg:order-1 lg:col-span-6'>
          <div>
            <badge-intro-title title="Top standards" />
          </div>
          <h3 class='mt-4 text-purple-900 sm:mt-5 h3'>
            We provide an environment that enables you to thrive.
          </h3>
          <div class='max-w-2xl mt-3 text-lg leading-loose text-purple-800'>
            <div class='px-4 py-10 sm:px-10 sm:py-2'>
              <!-- Teacher qualifications list -->
              <ul class="mt-5 space-y-5 text-lg text-purple-800">
                <!-- Item 1 -->
                <li class="flex items-center">
                  <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
                  <svg class="flex-shrink-0 hidden w-6 h-6 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle class="text-green-500 fill-current" cx="12" cy="12" r="12" />
                    <path class="text-white fill-current" d="M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z" />
                  </svg>
                  <span>A minimum of 3 years of experience
                  </span>
                </li>
                <!-- Item 2-->
                <li class="flex items-center">
                  <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
                  <svg class="flex-shrink-0 hidden w-6 h-6 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle class="text-green-500 fill-current" cx="12" cy="12" r="12" />
                    <path class="text-white fill-current" d="M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z" />
                  </svg>
                  <span>An advanced degree</span>
                </li>
                <!-- Item 3-->
                <li class="flex items-center">
                  <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
                  <svg class="flex-shrink-0 hidden w-6 h-6 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle class="text-green-500 fill-current" cx="12" cy="12" r="12" />
                    <path class="text-white fill-current" d="M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z" />
                  </svg>
                  <span>Amazing recommendations and positive reviews</span>
                </li>
                <!-- Item 4 -->
                <li class="flex items-center">
                  <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
                  <svg class="flex-shrink-0 hidden w-6 h-6 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle class="text-green-500 fill-current" cx="12" cy="12" r="12" />
                    <path class="text-white fill-current" d="M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z" />
                  </svg>
                  <span>A love and passion for teaching and helping children grow and succeed</span>
                </li>
                <!-- Item 5 -->
                <li class="flex items-center">
                  <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
                  <svg class="flex-shrink-0 hidden w-6 h-6 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle class="text-green-500 fill-current" cx="12" cy="12" r="12" />
                    <path class="text-white fill-current" d="M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z" />
                  </svg>
                  <span>A commitment to creating a positive classroom experience for all students, no matter their learning needs</span>
                </li>
              </ul>
            </div>
          </div>
          <div class='mt-6'>
            <button-more title="Learn more" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BadgeIntroTitle from '@/views/ui/BadgeIntroTitle.vue'
import ButtonMore from '@/views/ui/ButtonMore.vue'

export default {
  name: 'FeaturesPartial',
  components: { 
    ButtonMore, 
    BadgeIntroTitle 
  }
}
</script>