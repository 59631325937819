<template>
  <div>
    <!-- Hero -->
    <ProgramHeroPartial />
    <!-- Program Info -->
    <ProgramInfoPartial />
    <!-- Program description -->
    <DescriptionPartial />
    <!-- Pricing -->
    <PricingPartial />
    <!-- Gradient transition for CtA -->
    <div class='bg-white h-20 sm:h-28'></div>
  </div>
</template>

<script>
import DescriptionPartial from './partials/program/Description.vue'
import ProgramInfoPartial from './partials/program/Info.vue'
import PricingPartial from './partials/program/Pricing.vue'
import ProgramHeroPartial from './partials/program/ProgramHero.vue'

export default {
  name: 'ProgramView',
  components: {
    ProgramHeroPartial,
    ProgramInfoPartial,
    PricingPartial,
    DescriptionPartial,
  }
}
</script>