<template>
  <section class='relative w-full px-4 pb-12 sm:px-6 xl:px-8'>

    <!-- Container -->
    <div class='max-w-xl mx-auto lg:max-w-screen-xl'>
      <div class='grid gap-16 lg:grid-cols-2 xl:grid-cols-11 lg:gap-12 xl:gap-24'>

        <!-- Text content -->
        <div class='flex flex-col justify-center lg:col-span-1 xl:col-span-6 lg:order-2'>
          <div>
            <badge-intro-title title="Learn" />
          </div>
          <h2 class='mt-4 text-purple-900 sm:mt-5 h2'>
            English programs
          </h2>
          <p class="max-w-xl mt-4 text-xl leading-relaxed text-purple-800 md:mt-5">
            Pellentesque in ipsum id orci porta dapibus. Nulla quis lorem ut libero malesuada feugiat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.
          </p>
        
          <!-- Teacher qualifications box -->
          <div class='relative max-w-4xl mt-16 bg-yellow-100 rounded-xl sm:mt-14'>
            <span class="absolute flex items-center justify-center shadow-md left-6 sm:left-10 -top-7 rounded-2xl w-14 h-14 bg-gradient-to-br from-purple-600 to-purple-600">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-purple-50" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="15" cy="15" r="3" />
                <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
                <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
                <line x1="6" y1="9" x2="18" y2="9" />
                <line x1="6" y1="12" x2="9" y2="12" />
                <line x1="6" y1="15" x2="8" y2="15" />
              </svg>
            </span>
            <div class='px-4 py-10 mt-2 sm:px-10 sm:py-12'>
              <p class='text-lg font-semibold text-purple-900 sm:text-xl'>Ms Shamila pride herself on the quality of her teaching. You can expect the following from her:</p>
              <!-- Teacher qualifications list -->
              <ul class="mt-5 space-y-5 text-lg text-purple-800">
                <li 
                  v-for="(quality, index) in qualities" 
                  :key="index"
                  class="flex items-center"
                >
                  <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../assets/img/checkmark.svg'>
                  <svg class="flex-shrink-0 hidden w-6 h-6 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle class="text-green-500 fill-current" cx="12" cy="12" r="12" />
                    <path class="text-white fill-current" d="M16.28 8.28l-6.292 6.294-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7A1 1 0 0016.28 8.28z" />
                  </svg>
                  <span>{{ quality }}
                  </span>
                </li>
              </ul>
              <div class="mt-8">
                <button-more title="Meet the rest of the team" />
              </div>
            </div>
          </div>
        </div>

        <!-- Featured teachers section -->
        <div class='grid w-full gap-10 mx-auto sm:gap-8 lg:col-span-1 xl:col-span-5 lg:mt-20 sm:grid-cols-2 lg:gap-4 xl:gap-8 lg:order-1 sm:max-w-none sm:mx-0'>
          <!-- Product (Program) card -->
          <div
            v-for="(product, index) in products" 
            :key="product.id" 
            :class='evenCard(index)'
            class="flex flex-col justify-center"
          >
            <div class='aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4'>
              <img 
                class='object-cover shadow-md rounded-3xl' 
                :src='product.images[0]' 
                :alt='product.name'
              >
            </div>
            <div class='pl-2 sm:pl-0 mt-3.5 text-lg sm:text-center flex flex-col items-center'>
              <p class='font-semibold tracking-wide text-purple-800'>
                {{ product.name }}
              </p>
              <link-more title="Learn More" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductsInitMixin from '@/mixins/ProductsInit'
import { isEvenNumber } from '@/utils/NumberUtil'
import BadgeIntroTitle from '@/views/ui/BadgeIntroTitle.vue'
import ButtonMore from '@/views/ui/ButtonMore.vue'
import LinkMore from '@/views/ui/LinkMore.vue'

export default {
  name: "HomeProgramsPartial",
  components: {
    ButtonMore, 
    BadgeIntroTitle,
    LinkMore 
  },
  methods: {
    isEvenNumber,
    /* Concatenates some style if the current item is part of an even (numbered) card */ 
    evenCard(index) {
      return isEvenNumber(index + 1) ? "lg:-translate-y-10" : ""
    },
  },
  mixins: [
    ProductsInitMixin
  ],
  data () {
    return {
      qualities: [
        "A minimum of 3 years of experience",
        "An advanced degree",
        "Amazing recommendations and positive reviews",
        "A love and passion for teaching and helping children grow and succeed",
        "A commitment to creating a positive classroom experience for all students, no matter their learning needs"
      ]
    }
  },
}
</script>