import firebase from "firebase/compat/app";
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
// import { getFunctions } from "firebase/functions";
import Store from '@/store' 

const config = require('../firebase.config')

const firebaseApp = firebase.initializeApp(config);
const firestore = firebase.firestore()
const settings = {timestampsInSnapshots: true}
firestore.settings(settings)
firebaseApp.db = firestore

// const firebaseFunc = getFunctions(firebaseApp)

// const firebaseFunc = firebase.app().functions("us-central1")

// const functions = require("firebase/functions");
// const functions = firebase.functions
// firebaseApp.func = functions;

// Extended user profile's collection
const profilesCollection = firebaseApp.db.collection('profiles')

/**
 * Sign up
 * @param {*} email 
 * @param {*} password 
 * @returns 
 */
firebaseApp.signup = async(email, password, name) => {
  try {
    await firebase.auth().createUserWithEmailAndPassword(email, password)
    let user = firebase.auth().currentUser
    user.updateProfile({ 
      displayName: name 
    });

    // user.sendEmailVerification()
    Store.commit('setUser', user)
    
    return true
  } 
  catch(err) {
    return err
  }
}

firebaseApp.signin = async(email, password) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password)
    let user = firebase.auth().currentUser
    Store.commit('setUser', user)
    return true
  } 
  catch(err) {
    return err
  }
}

firebaseApp.signout = async() => {
  try {
    await firebase.auth().signOut()
    Store.commit('setUser', null)
    return true
  } 
  catch(err) {
    return err
  }
}

firebaseApp.resetPassword = async(email) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email)
    Store.commit('setUser', null)
    return true
  } 
  catch(err) {
    return err
  }
}

/**
 * Returns the custom claims of the logged in user
 * @returns 
 */
firebaseApp.getUserClaims = async() => {
  try {
    await firebase.auth().onAuthStateChanged(userAuth => {
      // console.log("userAuth", userAuth)
      if (userAuth) {
        firebase.auth().currentUser.getIdTokenResult()
          .then(tokenResult => {
            console.log("tokenResult", tokenResult.claims);
            return tokenResult.claims;
          });
      }
      return null
    });
  } 
  catch(err) {
    console.log(err)
    return err
  }
}

firebaseApp.init = () => {
  firebaseApp.db.collection('users').doc(Store.state.user.uid).collection('posts').onSnapshot(snapshot => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === 'added') {
        console.log('doc added', change.doc.data())
        Store.commit('addPost', change.doc)
      }
    })
  })
}

firebaseApp.getAllUSers = () => {
  try {
    let userList = []
    profilesCollection.get().then(snap => {
      snap.forEach(doc => {
        var user = doc.data();
        // Add User Id to list 
        user.id = doc.id;
        userList.push(user);
      });
    });
    return userList    
  } catch(err) {
    console.log('Err:', err)
  }
}

/**
 * Ref:  https://firebase.google.com/docs/auth/admin/errors
 * @param {*} errCode 
 * @returns 
 */
firebaseApp.getCustomErrorMessage = async(errCode) => {
  let errMsg = 'Error: '
  switch (errCode) {
    case 'auth/user-not-found':
      errMsg += 'There is no user corresponding to this identifier.'
      break;
    case 'auth/email-already-in-use':
      errMsg += 'The email address is already in use by another account.'
      break;
    default:
      errMsg += 'Something went wrong.'
  }
  return errMsg
}

// export { firebaseFunc }
export default firebaseApp
// export default { firebaseApp, firebaseFunc }