<template>
  <div class="h-full flex">
    <div class="relative h-full">
      <button 
        @click.prevent="showPanel"
        class="h-full text-gray-100"
      >
        <basket-icon 
          size="38" stroke-width="1"
          class="text-purple-800 inline-flex rounded-md border border-gray-300 shadow-sm bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
        />
        <span
          class="inline-block min-w-6 bg-blue-600 absolute right-0 top-0 rounded-full leading-normal"
          v-if="quantity"
        > <!-- right-0 -->
          {{ quantity }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import PriceMixin from '@/mixins/Price'
import ShoppingCart from '@/components/cart/ShoppingCart.vue';
import { BasketIcon } from "vue-tabler-icons";

export default {
  name: 'CartIndicator',
  components: {
    BasketIcon
  },
  computed: {
    quantity() {
      return this.$store.getters.totalQuantity;
    },
  },
  methods: {
    /* 
     * DEPRECATED 
     * Opens custom slide panel 
     */
    openCart() {
      window.scrollTo(0, 0);
      this.$store.commit('openCart');
    },
    /* 
     * @VueSlidePanel
     * Opens package VueSlidePanel which opens the cart
     */
    showPanel() {
      const cartPanel = this.$showPanel({
        component : ShoppingCart,
        // height: 100,
        width: 500,
        openOn: 'right',
        props: {
          //any data you want passed to your component
        }
      })
      cartPanel.promise
        .then(result => {
          this.$emit('close-panel'); // invoking closePanel() method
          console.log(result)
        });
    },
    /* 
     * @VueSlidePanel
     * Closes package VueSlidePanel which closes the cart
     */
    closePanel() {
      this.panelResult.hide();
    },
  },
  mixins: [PriceMixin]
}
</script>