export default {
  methods: {
    /* 
     * Formats a given price 
     */
    formatPrice(price) {
      if(!isNaN(price)){
        return price.toFixed(2).replace('.', ',');
      }
      return price
    },
    /* 
     * Rounds a Number to 2 Decimal Places 
     */
    to2DecimalPlaces(price) {
      if(!isNaN(price)) {
        return price / 100;
      }
      return price
    }
  },
};