<template>
  <section class='px-4 pb-12 overflow-hidden lg:pt-24 sm:px-6 lg:px-8'>
    <!-- Container -->
    <div class='max-w-xl mx-auto lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-32 lg:max-w-screen-xl '>
      <!-- Hero header -->
      <div class='py-16 lg:py-32'>
        <div>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Contact me today
          </span>
        </div>
        <h1 class='max-w-md mt-4 text-purple-900 h1'>
          I'd love to hear from you
        </h1>
        <p class='max-w-lg mt-3 text-xl leading-relaxed text-purple-800'>
          Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
        </p>
      </div>
      <!-- Contact form container -->
      <div class='relative'>
        <!-- Background decorations -->
        <img src='../../../assets/img/dots-large-grid.svg' class='absolute lg:hidden -right-16 -top-12 sm:-top-16 w-80 lg:left-14 lg:-top-16 lg:w-36 opacity-60'>
        <img src='../../../assets/img/dots-grid.svg' class='absolute hidden w-40 opacity-75 lg:block -right-16 -top-16 lg:left-14 lg:-top-16 lg:w-36'>
        <img src='../../../assets/img/dots-strip.svg' class='absolute hidden w-20 rotate-90 opacity-75 lg:block -right-16 top-1/2'>
        <!-- Contact form card -->
        <div class='relative z-10 w-full px-4 py-10 mx-auto bg-white shadow-xl rounded-3xl lg:mr-0 lg:ml-auto sm:p-16 lg:p-12 xl:p-14'>
          <div>
            <h3 class='text-2xl font-bold text-purple-900'>
              Send me a message
            </h3>
            <p class='text-purple-800 mt-0.5 text-opacity-90'>
              I'll get back to you within 24 hours.
            </p>
          </div>
          <!-- Contact form -->
          <contact-form></contact-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContactForm from '../../../components/ContactForm.vue'

export default {
  name: 'ContactHeroPartial',
  components: { 
    ContactForm 
  }
}
</script>