<template>
  <div class='relative'>
    <!-- Programs dropdown button -->
    <button 
      type='button' 
      aria-expanded="false" 
      @click="state = !state"
    >
      <div class="relative p-0.5 group">
        <span class="relative z-10 flex items-center text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600">
          Programs

          <!-- TablerIcon name: chevron-down -->
          <!-- Toggle class 'rotate-180' on dropdown open and close -->
          <chevron-down-icon 
            width="44" height="44" stroke-width="1.5"
            :class="{'rotate-180': state}"
            class="ml-1.5 w-5 h-5 transform duration-300 ease-in-out" 
          />
        </span>
        <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
      </div>
    </button>

    <!-- Dropdown -->
    <transition name="ddmenu">
      <div 
        v-show="state"
        style="display: none;"
        class='absolute z-20 w-screen max-w-xs p-4 mt-3 -translate-x-1/2 bg-white border shadow-lg left-1/2 border-gray-50 rounded-2xl'
      >
        <div>
          <router-link 
            v-for="(item, index) in items" :key="index"
            :to="item.link" 
            @click="close"
            class='block w-full py-4 transition duration-200 ease-in-out rounded-xl sm:p-5 hover:bg-purple-25 group'
          >
            <h5 class='text-lg font-semibold text-purple-600 ease-out'>
              {{ item.title }}
            </h5>
            <p class='mt-1 text-sm text-purple-800 opacity-90'>
              {{ item.details }}
            </p>
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickAwayMixin from '@/mixins/ClickAway'
import { ChevronDownIcon } from "vue-tabler-icons";

export default {
  name: "DropdownMenu",
  props: ['items'],
  components: {
    ChevronDownIcon
  },
  mixins: [ClickAwayMixin],
  data() {
    return {
      state: false
    }
  }
}
</script>

<style scoped>
.ddmenu-enter-active,
.ddmenu-leave-active {
  transition: opacity 0.2s, margin 0.2s;
}

.ddmenu-enter,
.ddmenu-leave-to {
  opacity: 0;
  margin-top: -20px;
}
</style>