import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueHead from 'vue-head'
import store from './store'
import i18n from '@/plugins/i18n'
import VModal from 'vue-js-modal'
import VueToast from 'vue-toast-notification';
import VueConfirmDialog from 'vue-confirm-dialog'
import VueSlideoutPanel from 'vue2-slideout-panel';
import VueTippy, { TippyComponent } from "vue-tippy";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'
import firebaseApp from './firebase-app'
// import {func} from './func.js'

import './assets/tailwind.scss'
import 'vue-toast-notification/dist/theme-sugar.css';   // theme-default.css
// vue-tippy styles
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";

import DefaultLayout from './layouts/Default.vue'
import AuthLayout from './layouts/Auth.vue'
import AuthsignLayout from './layouts/Authsign.vue'

Vue.component("tippy", TippyComponent);
Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)
Vue.component('authsign-layout', AuthsignLayout)

// Vue.prototype.$func = func
Vue.config.productionTip = false
Vue.config.ignoredElements = [/^ion-/]  // Ignore external component ion-icon


// Configure vue-js-modal
Vue.use(VModal, { 
  // dynamic: true, 
  dynamicDefaults: { 
    resizable: true,
    adaptive: true,
    draggable: true,
    clickToClose: false 
  } 
})

// Configure vue-toast-notification
//  Ref: https://github.com/ankurk91/vue-toast-notification/tree/v1.x
Vue.use(VueToast, {
  position: 'top'
});

// Configure vue-confirm-dialog
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

//-- User defined functions -------

// Returns true if the given number is an even number
var isEven = function(num) {
  return num % 2 == 0;
}

// Returns true if the given number is an odd number
var isOdd = function(num) {
  return Math.abs(num % 2) == 1;
}
//-- User defined functions END ----

// Register use defined functions 
Vue.filter('isEven', isEven);          
Vue.filter('isEven', isOdd);  

Vue.use(VueSlideoutPanel)
Vue.use(VueHead)
Vue.use(VueTippy);

let app

const init = () => {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
}

firebase.auth().onAuthStateChanged(authUser => {
  if( authUser ) {
    // Get claims and add it to state's user object
    firebase.auth().currentUser.getIdTokenResult().then(function ({ claims }) {
      store.commit('setUser', authUser)
      store.commit('setUserClaims', claims)
      
      // TODO Testing only remove when done
      console.log('authUser', authUser)
      console.log('claims', claims)

      firebaseApp.init()
    })
  } 
  else {
    store.commit('setUser', null)
  }
  init()
})
