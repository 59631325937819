<template>
  <div>
    <!-- Call to Action -->
    <CtaPartial />
    <!-- Footer -->
    <footer class='px-4 pt-16 space-y-8 bg-yellow-200 divide-y sm:pt-20 sm:px-6 lg:px-8 divide-purple-400/20'>

      <!-- Top section: blocks -->
      <div class='grid max-w-md mx-auto gap-y-8 sm:gapy-12 sm:gap-x-8 md:gap-x-12 sm:max-w-none lg:max-w-screen-2xl sm:grid-cols-2 lg:grid-cols-11 lg:gap-8 xl:gap-12'>

        <!-- Block 1 -->
        <div class='flex flex-col lg:mx-auto lg:col-span-4'>

          <!-- Logo -->
          <div class='flex items-center'>
            <div class="flex-grow-0 flex-shrink-0 w-60">
              <a href="index.html">
                <img src="../../assets/img/bright-logo-6.png" alt="Bright" class="h-auto"/>
              </a>
            </div>
          </div>

          <!-- Mission statement -->
          <div class='mt-6 text-lg text-purple-800'>
            Sed porttitor lectus nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam sit amet quam vehicula elementum sed sit.
          </div>

          <!-- Social links -->
          <div class='w-full mt-5 lg:mt-6'>
            <div class='flex justify-start space-x-4'>
              <!-- Instagram -->
              <a class="flex items-center justify-center w-10 h-10 text-white duration-300 ease-in-out bg-purple-500 rounded-full hover:bg-purple-600" href="#">
                <!-- Icon -->
                <brand-instagram-icon class="text-purple-100" size="20" stroke-width="1.5" />
              </a>
              <!-- Facebook -->
              <a class="flex items-center justify-center w-10 h-10 text-white duration-300 ease-in-out bg-purple-500 rounded-full hover:bg-purple-600" href="#">
                <!-- Icon -->
                <brand-facebook-icon class="text-purple-100" size="20" stroke-width="1.5" />
              </a>
              <!-- Twitter -->
              <a class="flex items-center justify-center w-10 h-10 text-white transition duration-300 ease-in-out bg-purple-500 rounded-full hover:bg-purple-600" href="#">
                <!-- Icon -->
                <brand-twitter-icon class="text-purple-100" size="20" stroke-width="1.5" />
              </a>
            </div>
          </div>
        </div>

        <!-- Block 2 -->
        <div class='flex-shrink sm:order-3 lg:order-none lg:col-span-2'>
          <h6 class='relative text-xl font-bold tracking-wide text-purple-900'>
            <span class='relative z-20'>Programs</span>
            <span class='absolute left-0 z-10 w-12 h-1 rounded-lg bg-gradient-to-r from-yellow-400 to-yellow-500 -bottom-1'></span>
          </h6>
          
          <!-- Program links -->
          <ul class='mt-6 text-lg divide-y divide-purple-400/20'>
            <li class='pb-2 font-medium text-purple-700 duration-300 ease-in-out hover:text-purple-600'>
              <a href='index.html'>
                English Conversation 
              </a>
            </li>
            <li class='py-2 font-medium text-purple-700 duration-300 ease-in-out hover:text-purple-600'>
              <a href='about-us.html'>
                IELTS Preparation
              </a>
            </li>
          </ul>

        </div>

        <!-- Block 3 -->
        <div class='flex-shrink sm:order-4 lg:order-none lg:col-span-2'>
          <h6 class='relative text-xl font-bold tracking-wide text-purple-900'>
            <span class='relative z-20'>Site Links</span>
            <span class='absolute left-0 z-10 w-12 h-1 rounded-lg bg-gradient-to-r from-yellow-400 to-yellow-500 -bottom-1'></span>
          </h6>

          <!-- Site links -->
          <ul class='mt-6 text-lg divide-y divide-purple-400/20'>
            <li class='pb-2 font-medium text-purple-700 duration-300 ease-in-out hover:text-purple-600'>
              <router-link to="/" exact>Home</router-link>
            </li>
            <li class='py-2 font-medium text-purple-700 duration-300 ease-in-out hover:text-purple-600'>
              <router-link to="/about">About</router-link>
            </li>
            <li class='py-2 font-medium text-purple-700 duration-300 ease-in-out hover:text-purple-600'>
              <router-link to="/lesson">Lessons</router-link>
            </li>
            <li class='py-2 font-medium text-purple-700 duration-300 ease-in-out hover:text-purple-600'>
              <router-link to="/community">Community</router-link>
            </li>
            <li class='pt-2 font-medium text-purple-700 duration-300 ease-in-out hover:text-purple-600'>
              <router-link to="/contact">Contact</router-link>
            </li>
          </ul>

        </div>

        <!-- Block 4 -->
        <div class='sm:order-2 lg:order-none lg:col-span-3 lg:mx-auto '>
          <h6 class='relative text-xl font-bold tracking-wide text-purple-900'>
            <span class='relative z-20'>Contact us</span>
            <span class='absolute left-0 z-10 w-12 h-1 rounded-lg bg-gradient-to-r from-yellow-400 to-yellow-500 -bottom-1'></span>
          </h6>

          <!-- Contact information -->
          <ul class='flex flex-col mt-6 space-y-5'>

            <!-- Address -->
            <li class="flex flex-shrink max-w-xs">
              <div>
                <span class="flex items-center justify-center bg-yellow-400 rounded-2xl w-11 h-11">
                  <!-- TablerIcon name: map-pin -->
                  <map-pin-icon class="text-purple-700" size="30" stroke-width="1.5" />
                </span>
              </div>
              <div class="flex-1 mt-0 ml-3 xl:ml-4">
                <h5 class="flex items-center text-base font-semibold text-purple-900">
                  Address
                </h5>
                <p class="mt-0.5 text-sm text-purple-800 leading-relaxed text-opacity-90">
                  958 McKinley Avenue Littleton, CO 80120
                </p>
              </div>
            </li>
            <!-- Email -->
            <li class="flex flex-shrink-0">
              <div>
                <span class="flex items-center justify-center bg-purple-200 rounded-2xl w-11 h-11">
                  <!-- TablerIcon name: mail -->
                  <mail-icon class="text-purple-700" size="30" stroke-width="1.5" />
                </span>
              </div>
              <div class="flex-1 ml-3 xl:ml-4">
                <h5 class="flex items-center text-base font-semibold text-purple-900">
                  Email
                </h5>
                <p class="mt-0.5 text-sm text-purple-800 leading-relaxed text-opacity-90">
                  me@englishwithshamila.co.uk
                </p>
              </div>
            </li>
            <!-- Phone number -->
            <li class="flex flex-shrink-0">
              <div>
                <span class="flex items-center justify-center rounded-2xl w-11 h-11 bg-rose-200">
                  <!-- Icon -->
                  <brand-whatsapp-icon class="text-purple-700" size="30" stroke-width="1.5" />
                </span>
              </div>
              <div class="flex-1 ml-3 xl:ml-4">
                <h5 class="flex items-center text-base font-semibold text-purple-900">
                  WhatsApp
                </h5>
                <p class="mt-0.5 text-sm text-purple-800 leading-relaxed text-opacity-90">
                  +44 7437 822258
                </p>
              </div>
            </li>
          </ul>
        </div>

      </div>

      <!-- Bottom section -->
      <div class='flex flex-col justify-between max-w-md py-8 mx-auto sm:flex-row sm:max-w-none lg:max-w-screen-2xl'>

        <!-- Copyright note -->
        <span class='text-base text-purple-800/90'>
          © 2021 English with Ms Shamila. All rights reserved.
        </span>

        <p class='mt-0.5 flex items-center text-purple-800/90'>
          Made with 
          <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mx-1" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 14c.83 .642 2.077 1.017 3.5 1c1.423 .017 2.67 -.358 3.5 -1c.83 -.642 2.077 -1.017 3.5 -1c1.423 -.017 2.67 .358 3.5 1" />
            <path d="M8 3a2.4 2.4 0 0 0 -1 2a2.4 2.4 0 0 0 1 2" />
            <path d="M12 3a2.4 2.4 0 0 0 -1 2a2.4 2.4 0 0 0 1 2" />
            <path d="M3 10h14v5a6 6 0 0 1 -6 6h-2a6 6 0 0 1 -6 -6v-5z" />
            <path d="M16.746 16.726a3 3 0 1 0 .252 -5.555" />
          </svg> 
          <span>
            by
            <a class='text-purple-700 hover:text-purple-900 hover:underline' href='#' target='_blank'>Human Developer</a>
          </span>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import CtaPartial from './Cta.vue'
import { BrandWhatsappIcon, MapPinIcon, MailIcon, BrandFacebookIcon, BrandTwitterIcon, BrandInstagramIcon } from "vue-tabler-icons";

export default {
  name: 'FooterPartial',
  components: { 
    CtaPartial,
    BrandWhatsappIcon,
    MapPinIcon, 
    MailIcon, 
    BrandFacebookIcon, 
    BrandTwitterIcon, 
    BrandInstagramIcon
  }
}
</script>