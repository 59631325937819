export const currenciesData = [
  { 
    value: 'usd', 
    name: 'USD',
    symbol: '$' 
  },
  { 
    value: 'gbp', 
    name: 'GBP',
    symbol: '£' 
  },
  { 
    value: 'sar', 
    name: 'SAR',
    symbol: '﷼' 
  }
];

function currencySymbol(iso3) {
  for (let i = 0; i < currenciesData.length; i++) {
    if (currenciesData[i].value == iso3) {
      return currenciesData[i].symbol
    }
  }
  return false
}

export { currencySymbol }