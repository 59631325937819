<template>
  <div class="first-letter:flex relative flex-col space-x-0.5 justify-center h-full">
    <button 
      @click="toggle" 
      class="self-center"
    >
      <world-icon 
        stroke-width="1"
        class="p-0.5 w-10 h-10 text-purple-800 self-center rounded-2xl border border-gray-300 shadow-sm bg-transparent 
              focus:outline-none focus:shadow-outline-purple focus:border-2 active:border-2 focus:bg-white" 
      />
    </button>
      <!-- Menu -->
        <div 
        v-show="state" 
        class="absolute right-0 z-100 bg-white rounded overflow-hidden shadow-lg"
      >
        
        <div class="p-5 flex flex-col flex-grow space-y-2 w-48">
          <div class="flex flex-col">
            <div class="text-sm">Language</div>
            <language-selector />
          </div>
          <div class="flex flex-col">
            <div class="text-sm">Currency</div>
            <currency-selector />
          </div>
          <div class="flex flex-row w-full">
            <button class="rounded-md bg-blue-600 text-white py-2 mt-2 w-full">Save</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import ClickAwayMixin from '@/mixins/ClickAway'
import LanguageSelector from '@/components/locale/LanguageSelector';
import CurrencySelector from '@/components/locale/CurrencySelector';
import { WorldIcon } from "vue-tabler-icons";

export default {
  name: 'LocaleMenu',
  methods: {
    /* Toggles the menu */ 
    toggle() {
      this.state = !this.state;
    },
  },
  components: {
    LanguageSelector,
    CurrencySelector,
    WorldIcon
  },
  mixins: [ClickAwayMixin],
  data() {
    return {
      state: false
    };
  }
}
</script>