<template>
  <form ref="form" class='mt-8' @submit.prevent="sendMail">
    <div class="form-result">
      <p class="alert alert-success" v-if="success && !error">Message sent successfully.</p>
      <p class="alert alert-error"   v-if="!success && error">Message failed.</p>
    </div>
    <div>
      <label for="name" class='ml-0.5 text-purple-900 font-medium text-sm'>Name *</label>
      <input 
        v-model="contactFormData.name"
        id="name" type='text' name='name' placeholder='Full name' required
        class='w-full p-4 mt-2 text-sm font-medium text-purple-700 placeholder-purple-700 duration-300 ease-in-out border-2 outline-none h-14 placeholder-opacity-70 rounded-2xl border-purple-50 focus:border-purple-200 focus:ring-purple-200 focus:outline-none' 
      >
    </div>
    <div class='mt-6'>
      <label for="email" class='ml-0.5 text-purple-900 font-medium text-sm'>Email *</label>
      <input 
        v-model="contactFormData.email"
        id="email" type='email' name='email' placeholder='somename@email.com' required
        class='w-full p-4 mt-2 text-sm font-medium text-purple-700 placeholder-purple-700 duration-300 ease-in-out border-2 outline-none h-14 placeholder-opacity-70 rounded-2xl border-purple-50 focus:border-purple-200 focus:ring-purple-200 focus:outline-none'
      >
    </div>
    <div class='mt-6'>
      <label for="phone" class='ml-0.5 text-purple-900 font-medium text-sm'>Phone</label>
      <input
        v-model="contactFormData.phone" 
        id="phone" type='text' name='phone' placeholder='(123) 456-789' 
        class='w-full p-4 mt-2 text-sm font-medium text-purple-700 placeholder-purple-700 duration-300 ease-in-out border-2 outline-none h-14 placeholder-opacity-70 rounded-2xl border-purple-50 focus:border-purple-200 focus:ring-purple-200 focus:outline-none'
      >
    </div>
    <div class='mt-6'>
      <label for="message" class='ml-0.5 text-purple-900 font-medium text-sm'>Message *</label>
      <textarea 
        v-model="contactFormData.message" 
        id="message" type='text' name='message' placeholder='Message' rows=5 required
        class='w-full p-4 mt-2 text-sm font-medium text-purple-700 placeholder-purple-700 duration-300 ease-in-out border-2 outline-none placeholder-opacity-70 rounded-2xl border-purple-50 focus:border-purple-200 focus:ring-purple-200 focus:outline-none'
      ></textarea>
    </div>
    <div class='flex justify-start mt-6'>
      <button
        href='#'
        type="submit"
        class="text-lg font-semibold text-purple-900 bg-yellow-500 btn hover:bg-yellow-600"
      >
        Send message
      </button>
    </div>
  </form>
</template>

<script>
/**
 * This template works with EmailJS!
 */
import EmailJs from 'emailjs-com'

export default {
  name: "ContactForm",
  methods: {
    /* Sends an email using EmailJS service */
    sendMail: function () {
      EmailJs.sendForm(
        process.env.VUE_APP_EMAILJS_SERVICE_ID, 
        process.env.VUE_APP_EMAILJS_TEMPLATE_ID, 
        this.$refs.form,  /* el.target */
        process.env.VUE_APP_EMAILJS_USER_ID
      )
      .then((result) => {
        console.log('SUCCESS!', result.text);
        this.resetForm();
      }, (error) => {
        console.log('FAILED...', error.text);
      });
    },

    /* Resets form data */ 
    resetForm: function () {
      this.contactFormData = {
        name: '',
        email: '',
        message: '',
      };
    },
  },
  data: function () {
    return {
      contactFormData: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      success: false,
      error: false,
    }
  },
}
</script>