<template>
  <div class="h-screen grid grid-cols-3">
    <div class="lg:col-span-2 col-span-3 space-y-8 px-12 bg-purple-25">
        <div class="mt-8 p-4 relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md">
            <div class="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                <div class="text-yellow-500">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 sm:w-5 h-6 sm:h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <div class="text-sm font-medium ml-3">Checkout</div>
            </div>
            <div class="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">Complete your shipping and payment details below.</div>
            <div class="absolute sm:relative sm:top-auto sm:right-auto ml-auto right-4 top-4 text-gray-400 hover:text-gray-800 cursor-pointer">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
            </div>
        </div>
        <!-- Shipping and Billing info -->
        <div class="rounded-md">
          <form id="payment-form" method="POST" action="">
            <section>
              <h2 class="uppercase tracking-wide text-lg font-semibold text-gray-700 my-2">Shipping & Billing Information</h2>
              <fieldset class="mb-3 bg-white shadow-lg rounded text-gray-600">
                <label class="flex border-b border-gray-200 h-12 py-3 items-center">
                  <span 
                    class="text-right px-4 mr-6"
                  >Name</span>
                  <input 
                    name="name" 
                    type="text" 
                    class="focus:outline-none focus:border-purple-200 border-none rounded w-full px-3 mr-3" 
                    placeholder="Full name" 
                    required="">
                </label>
                <label class="flex border-b border-gray-200 h-12 py-3 items-center">
                  <span 
                    class="text-right px-4 mr-6"
                  >Email</span>
                  <input 
                    name="email" 
                    type="email" 
                    class="focus:outline-none border-none rounded w-full px-3 mr-3" 
                    placeholder="email@example.com" 
                    required="">
                </label>
                <label class="flex border-b border-gray-200 h-12 py-3 items-center">
                  <span 
                    class="text-right px-4 mr-4"
                  >Location</span>
                  <places-selector 
                    css="py-1 bg-transparent cursor-pointer appearance-none focus:outline-none rounded"
                  />
                </label>
              </fieldset>
            </section>
          </form>
        </div>
        <!-- Payment info -->
        <div class="rounded-md">
          <section>
            <h2 class="uppercase tracking-wide text-lg font-semibold text-gray-700 my-2">Payment Information</h2>
            <fieldset class="mb-3 bg-white shadow-lg rounded text-gray-600">
              <label class="flex border-b border-gray-200 h-12 py-3 items-center">
                <!-- <span class="text-right px-2">Card</span> -->
                <!-- <input name="card" class="focus:outline-none px-3 w-full" placeholder="Card number MM/YY CVC" required=""> -->
                <!-- Stripe payment -->
                <span class="px-4 w-full">
                  <stripe-card-element />
                </span>
              </label>
            </fieldset>
          </section>
        </div>
        <button class="submit-button px-4 py-3 rounded-full bg-yellow-500 text-white focus:ring focus:outline-none w-full text-xl font-semibold transition-colors">
            Pay {{ orderTotal() }}
        </button>
    </div>
    <!-- Order summary -->
    <div class="col-span-1 bg-white lg:block hidden shadow-lg">
      <h1 class="py-6 border-b-2 text-xl text-gray-600 px-8">Order Summary</h1>
      <ul class="py-6 border-b space-y-6 px-8">
        <li 
          v-for="item in order"
          :key="item.product.id"
          class="grid grid-cols-6 gap-2 border-b-1"
        >
          <div class="col-span-1 self-center">
            <img 
              :src="`https://via.placeholder.com/50/${item.product.image}`" 
              :alt="item.product.imageAlt" 
              class="w-full rounded" 
            />
          </div>
          <div class="flex flex-col col-span-3 pt-2">
            <span class="text-gray-600 text-md font-semi-bold">{{ item.product.title }}</span>
            <span class="text-gray-400 text-sm inline-block pt-2">{{ item.product.subTitle }}</span>
          </div>
          <div class="col-span-2 pt-3">
            <div class="flex items-center space-x-2 text-sm justify-between">
              <span class="text-gray-400">{{ item.quantity }} x {{ formatPrice(item.product.price) }}</span>
              <span class="text-pink-400 font-semibold inline-block">
                {{ linePriceTotal(item.quantity, item.product.price) }}
              </span>
            </div>
          </div>
        </li>
      </ul>

      <div class="px-8 border-b">
        <div class="flex justify-between py-4 text-gray-600">
          <span>Subtotal</span>
          <span class="font-semibold text-pink-500">{{ formatPrice(subtotal) }}</span>
        </div>
        <div class="flex justify-between py-4 text-gray-600">
          <span>Shipping</span>
          <span class="font-semibold text-pink-500">{{ shipping() }}</span>
        </div>
      </div>
      <div class="font-semibold text-xl px-8 flex justify-between py-8 text-gray-600">
        <span>Total</span>
        <span>{{ orderTotal() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import PriceMixin from '@/mixins/Price'
import CartOrderMixin from '@/mixins/CartOrder'
import PlacesSelector from '@/components/PlacesSelector'
import StripeCardElement from '@/components/cart/StripeCardElement'

export default {
  name: 'AppCheckout',
  components: {
    PlacesSelector,
    StripeCardElement
  },
  methods: {
    /* Returns the total amount, formatted, for a specific item */ 
    linePriceTotal(qty, pr) {
      return this.formatPrice(
        this.calcTotalLinePrice(qty, pr)
      )
    },
    /* Returns the cost of shipping */ 
    shipping() {
      let shippingCost = 0  // TODO Should get this from store
      return shippingCost === 0 ? "Free" : this.formatPrice(shippingCost)
    },
    // 
    orderTotal() {
      return this.formatPrice(this.calcTotal())
    }
  },
  mixins: [
    PriceMixin, 
    CartOrderMixin
  ]
}
</script>