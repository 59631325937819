<template>
  <div>
    <router-link
      :to="href"
      class="mt-1.5 flex items-center py-0.5 px-0 w-[123px] max-w-full leading-6 text-left text-purple-600 no-underline bg-transparent border-b-2 border-purple-600 border-solid cursor-pointer hover:text-purple-500 transition duration-300 ease-in-out hover:border-purple-400 group"
    >
      <span class="text-base font-bold text-left">{{ title }}</span>
      <!-- Tablericon name: arrow-narrow-right -->
      <arrow-narrow-right-icon 
        size="44" stroke-width="2"
        class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" 
      />
    </router-link>
  </div>
</template>

<script>
import { ArrowNarrowRightIcon } from "vue-tabler-icons";

export default {
  name: 'LinkMore',
  props: {
    title: {
      type: String,
      default: 'More'
    },
    href: {
      type: String,
      default: '#'
    }
  },
  components: {
    ArrowNarrowRightIcon
  }
}
</script>