<template>
  <div class="w-full">
    <!-- Label -->
    <label id="listbox-label" class="block text-sm leading-5 font-medium text-gray-700">
      <slot name="SelectLabel"></slot>
    </label>
    <!-- Custom select -->
    <div 
      v-click-outside="closeDropdown"
      class="relative w-full"
    >
      <span class="inline-block w-full rounded-md shadow-sm">
        <button 
          @click="toggleDropdown" 
          type="button"
          aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" 
          class="cursor-pointer relative w-full rounded-md border border-gray-300 bg-white px-3 mr-3 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
        >
          <div class="flex items-center space-x-2 w-full">
            <img 
              v-if="current.img"
              :src="current.img"  
              class="flex-shrink-0 h-5 w-8"
              alt=""
            />
            <span class="block truncate pr-2">
              {{ current.name }}
            </span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
              <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
        </button>
      </span>

      <!-- Select popover, show/hide based on select state. -->
      <div v-show="isOpen" class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-100">
        <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="max-h-56 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">

          <!--
            Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
            Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
          -->
          <li 
            v-for="entry in data" 
            v-bind:key="entry.value"
            @click="select(entry)"
            tabindex="0" id="listbox-item-0" role="option" 
            class="w-full text-gray-900 select-none relative py-2 pl-3  cursor-pointer hover:text-white focus:outline-none focus:text-white hover:bg-purple-500 focus:bg-purple-500"
          >
            <div class="flex items-center space-x-2">
              <img 
                v-if="current.img"
                :src="entry.img.src" 
                :srcset="entry.img.srcset"
                class="flex-shrink-0 h-6 w-8"
                alt="" 
              />
              <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
              <span 
                v-bind:class="{ 'font-normal' : !isSelected(entry.value) , 'font-semibold' : isSelected(entry.value)}"
                class="block truncate" 
              >
                {{ entry.name }}
              </span>
            </div>

            <!--
              Checkmark, only display for selected option.
              Highlighted: "text-white", Not Highlighted: "text-indigo-600"
            -->
              <!--
              <span v-show="isSelected(d)" class="absolute inset-y-0 right-0 flex items-center pr-4">
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/>
                </svg>
              </span> 
              -->
          </li>

          <!-- More options... -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Ref: https://github.com/rezuankassim/vue-tailwind-custom-select
 *      https://github.com/rezuankassim/vue-tailwind-custom-select/blob/master/src/components/VueSelect.vue
 */
import ClickOutside from 'vue-click-outside'

export default {
  name: 'VueSelect',
  props: {
    data: Array,
    current: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      'isOpen' : false
    }
  },
  methods: {
    isSelected(value) {
      return this.current.value === value;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    openDropdown() {
      this.isOpen = true;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen; 
    },
    select(selection) {
      this.isOpen = false;
      this.$emit('valueSelect', { 
        value: selection.value, 
        name: selection.name, 
        img: !selection.img ? false: selection.img.src 
      });
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>