<template>
  <div>
    <!-- Country selector -->
    <select 
      v-model="selectedCountry" 
      :class="cssStyle"
    >
      <option value="">Select a Country</option>
      <option 
        v-for="(obj, country) in places" 
        :key="country" 
        :value="country"
      >{{country}}</option>
    </select>
    <!-- Region selector -->
    <select 
      v-model="selectedRegion"
      :disabled="regions.length == 0" 
      :class="cssStyle"
      class="mx-2"
    >
      <option value="">Select a Region</option>
      <option 
        v-for="(obj, region) in regions"
        :key="region"
      >{{region}}</option>
    </select>
    <!-- City selector -->
    <select 
      v-model="selectedCity"
      :disabled="cities.length == 0" 
      :class="cssStyle"
    >
      <option value="">Select a City</option>
      <option 
        v-for="(city, index) in cities"
        :key="index"
      >{{city}}</option>
    </select>
  </div>
</template>

<script>
import PlacesData from '@/data/places.json' 

export default {
  name: 'Countries',
  props: ["css"],
  data() {
    return {
      regions: [],
      cities: [],
      selectedCountry: "",
      selectedRegion: "",
      selectedCity: "",
      places: PlacesData   
    }
  },
  computed: {
    cssStyle() {
      return this.css
    }
  },
  watch: {
    selectedCountry: function() {
      // Clear previously selected values
      this.regions = [];
      this.cities = [];
      this.selectedRegion = "";
      this.selectedCity = "";
      // Populate list of countries in the second dropdown
      if (this.selectedCountry.length > 0) {
        this.regions = this.places[this.selectedCountry]
      }
    },
    selectedRegion: function() {
      // Clear previously selected values
      this.cities = [];
      this.selectedCity = "";
      // Now we have a continent and country. Populate list of cities in the third dropdown
      if (this.selectedCountry.length > 0) {
        this.cities = this.places[this.selectedCountry][this.selectedRegion]
      }
    }
  }
}
</script>