/**
 * Initializes the products (or programs)
 */
 export default {
  computed: {
    /* Return all products */ 
    products() {
      return this.$store.state.items
      // return this.$store.getters.products;
    },
    /* Return all products */ 
    prices() {
      return this.$store.state.itemPrices
      // return this.$store.getters.prices;
    }
  },
  async mounted() {

    try {
      /* Load the products from the Stripe's API */ 
      // await this.$store.dispatch("loadProducts");

      /* Load the (product) prices from the Stripe's API */ 
      // await this.$store.dispatch("loadPrices");

      await this.$store.dispatch("loadProductsMergePrices");
    }
    catch(ex) {
      console.message(ex.message)
    }
  }
 }