<template>
  <section class='pb-24 bg-white sm:pb-32'>

    <!-- Container -->
    <div class='max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8'>

      <!-- Sunrise image -->
      <img class='mx-auto w-72' src='../../assets/img/sunrise.svg'>

      <!-- Header -->
      <h2 class='max-w-3xl mx-auto mt-6 text-center text-purple-900 h1'>
        <span class="block">Giving yourself a</span>

        <!-- Underlined text -->
        <span class="relative block">
          <span class='relative'>
            <img class='absolute inset-0 transform translate-y-9 sm:translate-y-11 xl:translate-y-14' src='../../assets/img/underline-simple-light-purple.svg'>
            <span class='relative'>better future</span>
          </span>
        </span>

      </h2>

      <!-- CTA button -->
      <div class='flex justify-center mt-12 xl:mt-14'>
        <a
            href='#'
            class="text-lg font-semibold text-purple-900 bg-yellow-500 btn hover:bg-yellow-600 group"
          >
            Enroll today

            <!-- Tablericon name: arrow-narrow-right -->
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="5" y1="12" x2="19" y2="12" />
              <line x1="15" y1="16" x2="19" y2="12" />
              <line x1="15" y1="8" x2="19" y2="12" />
            </svg>
          </a>
          
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'CtaPartial'
}
</script>