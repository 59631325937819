<template>
  <div>
    <vue-select
      :data="currencies" 
      :current="selectedCurrency" 
      v-on:valueSelect="onValueSelect"
    >
      <!-- <template v-slot:SelectLabel>Wrestlers</template> -->
    </vue-select> 
  </div>
</template>

<script>
import VueSelect from '@/components/VueSelect.vue'
import { currenciesData } from '@/data/currencies';

export default {
  name: 'CurrencySelector',
  components: {
    VueSelect
  },
  methods: {
    onValueSelect(selection) {
      this.selectedCurrency.value = selection.value;
      this.selectedCurrency.name = selection.name;
      // this.selectedCurrency.img = selection.img;
      this.$store.commit('SET_CURRENCY', selection.value)
    }
  },
  data() {
    return { 
      selectedCurrency: { value: 'sar', name: 'SAR', img: false },
      currencies: currenciesData
    }
  }
}
</script>