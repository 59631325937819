<template>
  <div>
    <router-link
      :to="href"
      class="font-medium text-white bg-purple-600 btn-sm hover:bg-purple-500 group"
    >
      {{ title }}
      <!-- Tablericon name: arrow-narrow-right -->
      <arrow-narrow-right-icon 
        size="44" stroke-width="2"
        class="w-5 h-5 ml-3 group-hover:animate-horizontal-bounce" 
      />
    </router-link>
  </div>
</template>

<script>
import { ArrowNarrowRightIcon } from "vue-tabler-icons";

export default {
  name: 'ButtonMore',
  props: {
    title: {
      type: String,
      default: 'More'
    }, 
    href: {
      type: String,
      default: '#'
    }
  },
  components: {
    ArrowNarrowRightIcon
  }
}
</script>