<template>
  <li class="accordion__item w-full px-5 py-6 bg-white sm:px-12 sm:py-8 rounded-3xl">
    <div 
      :class="{'accordion__trigger_active': visible}"
      class="accordion__trigger flex items-center justify-between w-full text-lg sm:text-xl group"
      @click="open"
    >
      <span class='font-medium text-left text-purple-900 duration-300 ease-in-out group-hover:text-purple-600'>
        <slot name="accordion-trigger"></slot>
      </span>
        <chevron-down-icon 
          stroke-width="2"
          :class="{'rotate-180': flipArrow}"
          class="flex-shrink-0 w-5 h-6 ml-3 text-purple-700 duration-300 ease-in-out sm:w-6 sm:h-6 sm:ml-6 group-hover:text-purple-600" 
        />
    </div>

    <transition 
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-show="visible" class="accordion__content">
        <ul class='mt-3 text-base leading-relaxed text-purple-800 sm:text-lg'>
          <slot name="accordion-content"></slot>
        </ul>
      </div>
    </transition>
  </li>
</template>


<script>
import { ChevronDownIcon } from "vue-tabler-icons";

export default {
  name: 'AccordionItem',
  props: {},
  components: {
    ChevronDownIcon
  },
  inject: ["Accordion"],
  computed: {
    visible() {
      return this.index == this.Accordion.active;
    }
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
        this.flipArrow = false;
      } 
      else {
        this.Accordion.active = this.index;
        this.flipArrow = true;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  },
  created() {
    this.index = this.Accordion.count++;
  },
  data() {
    return {
      flipArrow: false,
      index: null
    };
  }
};
</script>

<style lang="scss" scoped>
.accordion__item {
  cursor: pointer;
}

.accordion__trigger {
  display: flex;
  justify-content: space-between;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
