<template>
  <div>
    <!-- vie-confirm-dialog -->
    <vue-confirm-dialog></vue-confirm-dialog>
    <!-- Auth Navbar -->
    <AuthNavbar />
    <!-- Auth Sidebar -->
    <AuthSidebar />
    <div class="flex overflow-hidden bg-white pt-16">
      <div id="main-content" class="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64">
        <!-- Main Content -->
        <main>
          <slot />
        </main>
        <!-- Footer -->
        <AuthFooter />
      </div>
    </div>
  </div>
</template>

<script>
import AuthFooter from '../views/partials/AuthFooter.vue'
import AuthNavbar from '../views/partials/AuthNavbar.vue'
import AuthSidebar from '../views/partials/AuthSidebar.vue'

export default {
  name: 'AuthLayout',
  components: { 
    AuthNavbar,
    AuthSidebar,
    AuthFooter
  },
  // computed: {
  //   user() {
  //     return this.$store.state.user
  //   }
  // }
}
</script>

<style>
/* Ovewriting VueConfirmDialog default styles */
.vc-overlay {
  background-color: rgba(0,0,0,0.7) !important;
}
</style>