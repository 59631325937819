<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
const DEFAULT_LAYOUT = "default";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || DEFAULT_LAYOUT) + '-layout';
    },
  }
}
</script>

<style>
/* Overriding vue-js-modal's properties */
.vm--overlay { 
  background-color: rgb(0, 0, 0, 0.8) !important; 
}
.vm--modal {
  border-radius: 24px !important;
}

/* element {
    top: 51px;
    left: 375px;
    width: 600px;
    height: 300px;
} */

</style>
