<template>
  <div>
    <!-- Home hero -->
    <HeroPartial />
    <!-- Gradient transition -->
    <div class='w-full h-32 sm:h-36 xl:h-44 bg-gradient-to-b from-purple-25 to-yellow-100'></div>
    <!-- Home features -->
    <FeaturesPartial />
    <!-- Latest Lessons -->
    <LatestLessonsPartial />
    <!-- Gradient transition -->
    <div class='w-full h-32 sm:h-36 xl:h-44 bg-gradient-to-b from-yellow-100 to-purple-50'></div>
    <!-- Programs -->
    <HomeProgramsPartial />
    <!-- Testimonials -->
    <TestimonialsPartial />
    <!-- Faq's -->
    <FaqPartial />
    <!-- Gradient transition -->
    <div class='w-full h-40 sm:h-48 xl:h-52 bg-gradient-to-b from-yellow-100 to-white'></div>
  </div>
</template>

<script>
import HeroPartial from '@/views/partials/home/Hero.vue'
import FeaturesPartial from '@/views/partials/home/Features.vue'
import LatestLessonsPartial from '@/views/partials/home/LatestLessons.vue'
import HomeProgramsPartial from './partials/home/Programs.vue'
import TestimonialsPartial from './partials/home/Testimonials.vue'
import FaqPartial from './partials/home/Faq.vue'

export default {
  name: 'HomeView',
  components: {
    HeroPartial,
    FeaturesPartial,
    LatestLessonsPartial,
    HomeProgramsPartial,
    TestimonialsPartial,
    FaqPartial,
  }
}
</script>
