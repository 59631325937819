<template>
  <section class='relative px-4 py-16 overflow-hidden lg:py-20 sm:px-6 lg:px-8'>

    <!-- Background decorations -->
    <img src='../../../assets/img/dots-large-grid.svg' class='absolute right-0 h-auto transform opacity-25 lg:opacity-40 top-12 w-54'>
    <img src='../../../assets/img/dots-large-grid.svg' class='absolute h-auto transform opacity-40 -left-36 bottom-36 w-54'>

    <!-- Container -->
    <div class='max-w-xl mx-auto lg:max-w-screen-xl lg:grid lg:grid-cols-12 lg:gap-8'>

      <!-- Section header text -->
      <div class='flex flex-col justify-center lg:col-span-5'>
        <div>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Now enrolling for preschool
          </span>
        </div>

        <div class='relative'>
          <h1 class='max-w-xl mt-4 text-purple-900 sm:mt-5 h1 lg:max-w-none'>
            Stay in the loop
          </h1>

          <!-- Arrow to links -->
          <img src='../../../assets/img/arrow-right-over.svg' class='absolute hidden transform -top-3 xl:block md:-right-8 w-14 md:w-32 rotate-12' alt=''>

        </div>

        <p class='max-w-2xl mt-3 text-xl leading-loose text-purple-800 sm:mt-4'>
          Sed porttitor lectus nibh. Cras ultricies ligula sed magna dictum porta. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.
        </p>  

        <!-- Enroll CTA-->
        <div class='mt-10 font-semibold lg:mt-12'>
          <p class='text-purple-800'>Not enrolled in our school?</p> 
          <a
            href='#'
            class="mt-2 text-lg font-semibold text-purple-900 bg-yellow-500 sm:mt-3 btn-sm hover:bg-yellow-600 group"
          >
            Enroll today

            <!-- Tablericon name: arrow-narrow-right -->
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="5" y1="12" x2="19" y2="12" />
              <line x1="15" y1="16" x2="19" y2="12" />
              <line x1="15" y1="8" x2="19" y2="12" />
            </svg>
            
          </a>
        </div>

      </div>

      <!-- Important parent links container -->
      <div class='relative mt-12 sm:mt-16 lg:mt-0 xl:pl-12 lg:col-span-7'>

        <!-- Important links -->
        <div class='relative w-full mx-auto sm:grid sm:gap-6 md:max-w-3xl lg:gap-5 lg:max-w-lg sm:grid-cols-4 lg:grid-cols-2 auto-rows-fr'>

          <!-- Link 1 -->
          <a href='#' class='flex flex-col justify-center w-full p-8 duration-300 ease-in-out transform shadow-lg sm:col-span-2 lg:col-span-1 bg-purple-50 rounded-2xl hover:scale-105 group'>

            <!-- Link description -->
            <div class='flex-1'>

              <!-- Tablericon name: messages -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-purple-700" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
                <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
              </svg>

              <p class='mt-4 text-xl font-semibold text-purple-900'>
                Community
              </p>
              <p class='mt-2 text-sm text-purple-800'>
                Join the parent-teacher group chat
              </p>
            </div>
            
            <div class='flex justify-end w-full mt-6'>

              <!-- Tablericon name: chevrons-right -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-purple-500 duration-300 ease-in-out group-hover:animate-horizontal-bounce hover:text-purple-600" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 7 17" />
                <polyline points="13 7 18 12 13 17" />
              </svg>

            </div>
          </a>

          <!-- Link 2 -->
          <a href='#' class='flex flex-col justify-center w-full p-8 mt-6 duration-300 ease-in-out bg-yellow-200 shadow-lg rounded-2xl sm:mt-0 sm:col-span-2 lg:col-span-1 lg:translate-y-28 hover:scale-105 group'>

            <!-- Link description -->
            <div class='flex-1'>

              <!-- Tablericon name: list-check -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-purple-700" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M3.5 5.5l1.5 1.5l2.5 -2.5" />
                <path d="M3.5 11.5l1.5 1.5l2.5 -2.5" />
                <path d="M3.5 17.5l1.5 1.5l2.5 -2.5" />
                <line x1="11" y1="6" x2="20" y2="6" />
                <line x1="11" y1="12" x2="20" y2="12" />
                <line x1="11" y1="18" x2="20" y2="18" />
              </svg>

              <p class='mt-4 text-xl font-semibold text-purple-900'>
                Child Care Checklist
              </p>
              <p class='mt-2 text-sm text-purple-800'>
                Confidently choose the best program for your child
              </p>
            </div>

            <div class='flex justify-end w-full mt-6'>

              <!-- Tablericon name: chevrons-right -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-purple-500 duration-300 ease-in-out group-hover:animate-horizontal-bounce hover:text-purple-600" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 7 17" />
                <polyline points="13 7 18 12 13 17" />
              </svg>

            </div>
          </a>

          <!-- Link 3 -->
          <a href='#' class='flex flex-col justify-center w-full p-8 mt-6 duration-300 ease-in-out shadow-lg bg-rose-50 sm:col-span-2 sm:mt-0 lg:col-span-1 rounded-2xl hover:scale-105 group'>

            <!-- Link description -->
            <div class='flex-1'>

              <!-- Tablericon name: meat -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-purple-700" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M13.62 8.382l1.966 -1.967a2 2 0 1 1 3.414 -1.415a2 2 0 1 1 -1.413 3.414l-1.82 1.821" />
                  <ellipse transform="rotate(45 8.025 16.475)" cx="8.025" cy="16.475" rx="7" ry="3" />
                <path d="M7.5 16l1 1" />
                <path d="M12.975 21.425c3.905 -3.906 4.855 -9.288 2.121 -12.021c-2.733 -2.734 -8.115 -1.784 -12.02 2.121" />
              </svg>

              <p class='mt-4 text-xl font-semibold text-purple-900'>
                Meal Program
              </p>
              <p class='mt-2 text-sm text-purple-800'>
                Learn about our healthy meal program
              </p>
            </div>

            <div class='flex justify-end w-full mt-6'>

              <!-- Tablericon name: chevrons-right -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-purple-500 duration-300 ease-in-out group-hover:animate-horizontal-bounce hover:text-purple-600" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 7 17" />
                <polyline points="13 7 18 12 13 17" />
              </svg>

            </div>
          </a>

          <!-- Link 4 -->
          <a href='#' class='flex flex-col justify-center w-full p-8 mt-6 duration-300 ease-in-out shadow-lg sm:mt-0 lg:col-start-auto sm:col-span-2 lg:col-span-1 lg:translate-y-28 bg-teal-50 rounded-2xl hover:scale-105 group'>

            <!-- Link description -->
            <div class='flex-1'>

              <!-- Tablericon name: virus -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-purple-700" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="12" r="5" />
                <path d="M12 7v-4m-1 0h2" />
                <path d="M12 7v-4m-1 0h2" transform="rotate(45 12 12)" />
                <path d="M12 7v-4m-1 0h2" transform="rotate(90 12 12)" />
                <path d="M12 7v-4m-1 0h2" transform="rotate(135 12 12)" />
                <path d="M12 7v-4m-1 0h2" transform="rotate(180 12 12)" />
                <path d="M12 7v-4m-1 0h2" transform="rotate(225 12 12)" />
                <path d="M12 7v-4m-1 0h2" transform="rotate(270 12 12)" />
                <path d="M12 7v-4m-1 0h2" transform="rotate(315 12 12)" />
              </svg>

              <p class='mt-4 text-xl font-semibold text-purple-900'>
                Covid Response
              </p>
              <p class='mt-2 text-sm text-purple-800'>
                Learn what we're doing to keep your child safe
              </p>
            </div>

            <div class='flex justify-end w-full mt-6'>

              <!-- Tablericon name: chevrons-right -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-purple-500 duration-300 ease-in-out group-hover:animate-horizontal-bounce hover:text-purple-600" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 7 17" />
                <polyline points="13 7 18 12 13 17" />
              </svg>

            </div>
          </a>

          <!-- Link 5 -->
          <a href='#' class='flex flex-col justify-center w-full p-8 mt-6 duration-300 ease-in-out shadow-lg bg-blue-50 sm:mt-0 sm:col-span-2 lg:col-span-1 rounded-2xl sm:col-start-2 hover:scale-105 group'>

            <!-- Link description -->
            <div class='flex-1'>

              <!-- Tablericon name: photo -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-purple-700" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="15" y1="8" x2="15.01" y2="8" />
                <rect x="4" y="4" width="16" height="16" rx="3" />
                <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
                <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
              </svg>
              
              <p class='mt-4 text-xl font-semibold text-purple-900'>
                School Gallery
              </p>
              <p class='mt-2 text-sm text-purple-800'>
                View our school gallery  
              </p>
            </div>

            <div class='flex justify-end w-full mt-6'>

              <!-- Tablericon name: chevrons-right -->
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-purple-500 duration-300 ease-in-out group-hover:animate-horizontal-bounce hover:text-purple-600" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 7 17" />
                <polyline points="13 7 18 12 13 17" />
              </svg>

            </div>
          </a>

        </div>

      </div>

    </div>
    
  </section>
</template>

<script>
export default {
  name: 'CommunityHeroPartial'
}
</script>