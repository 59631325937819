<template>
  <section class='relative px-4 pt-16 sm:px-6 lg:px-8'>
    <div class='max-w-screen-xl mx-auto'>

      <!-- Header -->
      <div class="relative">
        <div class='flex justify-center'>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Where great minds grow
          </span>
        </div>
        <h2 class="max-w-3xl mx-auto mt-4 text-center text-purple-900 h1">
          English Programs
        </h2>
        <p class="max-w-2xl mx-auto mt-4 text-xl leading-relaxed text-center text-purple-800 sm:mt-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aenean et tortor at risus viverra adipiscing at in.
        </p>

        <!-- CTA button -->
        <div class='flex justify-center mt-8'>
          <a
            href='#'
            class="text-lg font-semibold text-purple-900 bg-yellow-500 btn hover:bg-yellow-600 group"
          >
            Enroll today

            <!-- Tablericon name: arrow-narrow-right -->
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="5" y1="12" x2="19" y2="12" />
              <line x1="15" y1="16" x2="19" y2="12" />
              <line x1="15" y1="8" x2="19" y2="12" />
            </svg>

          </a>
        </div>
      </div>

      <!-- Hero image -->
      <div class='relative z-10 mt-14 sm:mt-16'>
        <div class='aspect-w-3 aspect-h-2 sm:aspect-w-16 sm:aspect-h-9'>
          <img class='object-cover w-full h-full shadow-xl rounded-3xl' src='../../../assets/img/preschool.jpg' alt='preschool' />
        </div>
      </div>
    </div>

    <!-- Background overlay effect -->
    <div class='absolute inset-x-0 bottom-0 top-auto z-0 hidden h-64 bg-white sm:block'></div>
  </section>
</template>

<script>
export default {
  name: 'ProgramHeroPartial'
}
</script>