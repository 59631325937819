/**
 * Methods and other logic related to the shopping cart's oder
 */
export default {
  methods: {
    /* Calculates the transaction's line item value */ 
    calcTotalLinePrice(qty, unitPrice) {
      return unitPrice * qty
    },
    /* Calculates shipping */ 
    calcTotalShipping() {
      return 0
    },
    /* Calculates the oder's total amount, which is the sum of the subtotal, shipping and taxes (if applicable)  */ 
    calcTotal() {
      return this.subtotal + this.calcTotalShipping()
    }
  },
  computed: {
    /* Returns the order as in cart */ 
    order() {
      console.log(this.$store.getters.order)
      return this.$store.getters.order;
    },
    /* Calculates the subtotal of the order */ 
    subtotal() {
      return this.$store.getters.subTotal;
    },
    /* Returns a list of (all) products in the cart */
    productsAdded() {
      return this.$store.state.cart;
    },
    /* Returns the order's total qty  */ 
    numOfItems() {
      return this.$store.state.totalQuantity;
    }
  },
};