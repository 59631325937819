<template>
  <section class='relative w-full px-4 pt-56 bg-white -mb-52 -translate-y-52 sm:pt-64 lg:pt-72 sm:px-6 lg:px-8'>

    <!-- Contact information container -->
    <div class='max-w-xl mx-auto lg:max-w-screen-xl'>

      <!-- Section header -->
      <div class='lg:grid lg:grid-cols-2 lg:gap-16 xl:gap-32'>
        <div class='flex items-center'>
          <h2 class='max-w-4xl text-purple-900 h2'>
            Contact information
          </h2>
        </div>
        <div class='flex items-center mt-3 sm:mt-4 lg:mt-0'>
          <p class='text-lg text-purple-800 sm:text-xl text-opacity-90'>
            Dictum urna sed consectetur neque tristique pellentesque. 
            Blandit amet, sed aenean erat arcu morbi.
          </p>
        </div>
      </div>

      <!-- Contact information cards -->
      <div class='grid grid-cols-1 gap-4 mt-12 sm:mt-14 lg:mt-20 sm:grid-cols-4 lg:grid-cols-3 sm:gap-6 xl:gap-12'>

        <!-- Address card -->
        <div class='px-4 py-8 bg-yellow-200 sm:col-span-2 lg:col-span-1 sm:p-8 rounded-3xl'>
          <div class="flex sm:flex-col lg:flex-row">
            <div>
              <span class="flex items-center justify-center bg-yellow-400 rounded-2xl w-14 h-14">

                <!-- TablerIcon name: map-pin -->
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-purple-700" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <circle cx="12" cy="11" r="3" />
                  <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                </svg>

              </span>
            </div>
            <div class="flex-1 ml-6 sm:mt-3 lg:mt-0 sm:ml-0 lg:ml-6">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                Address
              </h5>
              <p class="mt-1.5 text-base text-purple-800 leading-relaxed">
                958 McKinley Avenue Littleton, CO 80120
              </p>
            </div>
          </div>
        </div>

        <!-- Email card -->
        <div class='px-4 py-8 sm:col-span-2 lg:col-span-1 sm:p-8 sm:py-10 rounded-3xl bg-purple-50'>
          <div class="flex sm:flex-col lg:flex-row">
            <div>
              <span class="flex items-center justify-center bg-purple-200 rounded-2xl w-14 h-14">

                <!-- TablerIcon name: mail -->
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-purple-700" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <rect x="3" y="5" width="18" height="14" rx="2" />
                  <polyline points="3 7 12 13 21 7" />
                </svg>

              </span>
            </div>
            <div class="flex-1 ml-6 sm:mt-3 lg:mt-0 sm:ml-0 lg:ml-6">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                Email me
              </h5>
              <p class="mt-1.5 text-base text-purple-800 leading-relaxed">
                hello@englishwithshamila.co.uk
              </p>
            </div>
          </div>
        </div>

        <!-- Phone number card -->
        <div class='px-4 py-8 sm:col-start-2 sm:col-span-2 lg:col-start-3 lg:col-span-1 sm:p-8 sm:py-10 rounded-3xl bg-rose-50'>
          <div class="flex sm:flex-col lg:flex-row">
            <div>
              <span class="flex items-center justify-center rounded-2xl w-14 h-14 bg-rose-200">
                <!-- Icon -->
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-purple-700" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"></path>
                </svg>
              </span>
            </div>
            <div class="flex-1 ml-6 sm:mt-3 lg:mt-0 sm:ml-0 lg:ml-6">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                WhatsApp me
              </h5>
              <p class="mt-1.5 text-base text-purple-800 leading-relaxed">
                +44 7437 822258
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- Google map widget -->
    <div class='mt-16 lg:mx-auto lg:mt-24 rounded-3xl lg:max-w-screen-xl'>
        <iframe class='w-full rounded-3xl' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3086.1624232420972!2d-76.62270638437457!3d39.329905428938126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c804df8502f88d%3A0x303d58494fa04c66!2sJohns%20Hopkins%20University!5e0!3m2!1sen!2sus!4v1629758627091!5m2!1sen!2sus" height="600" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    
  </section>
</template>

<script>
export default {
  name: 'InformationPartial'
}
</script>