import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from './store'

/*
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    console.log(err)
    // if (err.name != "NavigationDuplicated") {
    //   throw err;
    // }
  })
};
*/

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: require("./views/Home.vue").default
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
  },
  {
    path: '/program',
    name: 'Program',
    component: require("./views/Program.vue").default   // load sync home
  },
  {
    path: '/lesson',
    name: 'Lesson',
    component: require("./views/Lesson.vue").default   // load sync home
  },
  {
    path: '/community',
    name: 'Community',
    component: require("./views/Community.vue").default   // load sync home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: require("./views/Contact.vue").default   // load sync home
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: require("./views/Checkout.vue").default,
    meta: { 
      layout: "custom",
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    meta: { 
      layout: "authsign",
      requiresAuth: false
    },
    component: () => import("./views/backend/Signup.vue")
  },
  {
    path: '/signin',
    name: 'Signin',
    meta: { 
      layout: "authsign",
      requiresAuth: false
    },
    component: () => import("./views/backend/Signin.vue")
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: { 
      layout: "authsign",
      requiresAuth: false
    },
    component: () => import("./views/backend/ResetPassword.vue")
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { 
      layout: "auth",
      requiresAuth: true
    },
    component: () => import("./views/backend/Dashboard.vue")
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { 
      layout: "auth",
      requiresAuth: true
    },
    component: () => import("./views/backend/Profile.vue")
  },
  {
    path: '/users',
    name: 'Users',
    meta: { 
      layout: "auth",
      requiresAuth: true
    },
    component: () => import("./views/backend/Users.vue")
  },
  {
    path: '/posts',
    name: 'Posts',
    meta: { 
      layout: "auth",
      requiresAuth: true
    },
    component: () => import("./views/backend/Posts.vue")
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  /* 
   * Options: 'reload', 'throw' and default to `throw` to avoid breaking changes
   */
  duplicateNavigationPolicy: 'ignore',
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }  
})

router.beforeEach(async (to, from, next) => {
  // (1) Check if current route requires authentication
  // (2) Check if user is logged in 
  if(to.meta.requiresAuth && !Store.state.user) {
    if (to.path !== '/signin') {
      next({ path: '/signin' })
    }
  }
  next()

  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   next({
  //     path: '/signin',
  //     query: {
  //       redirect: to.fullPath
  //     }
  //   })
  // }
})

export default router
