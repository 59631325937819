<template>
  <section class="text-gray-600 body-font bg-purple-25">
    <div class="container xl:px-32 px-5 py-36 mx-auto flex flex-wrap items-center">
      <!-- Title and Tagline -->
      <div class="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
        <div>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-yellow-500 rounded-full shadow-md -rotate-1'>
            Authentication
          </span>
        </div>
        <h1 class='max-w-xl mt-4 text-center text-purple-900 sm:mt-5 lg:text-left h1 lg:max-w-none'>
          English with<br> Ms Shamila
        </h1>
        <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-purple-800 lg:text-left'>
          Where English has met its match!
        </p>
      </div>
      <!-- Form -->
      <slot />
    </div>
    <div class="container mx-auto px-6">
        <div class="mt-10 border-t-2 border-purple-200 flex flex-col items-center">
            <div class="sm:w-2/3 text-center py-6">
                <p class="text-sm text-blue-700 font-bold mb-2">
                  English with Ms Shamila © 2021 Created by Human Developer
                </p>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AuthsignLayout'
}
</script>