<template>
  <div>
    <!-- List -->
    <LessonList />
    <!-- Gradient transition -->
    <div class='w-full h-20 sm:h-28 xl:h-32 bg-gradient-to-b from-purple-25 to-white'></div>
  </div>
</template>

<script>
import LessonList from './partials/lesson/List.vue'

export default {
  name: "LessonView",
  components: {
    LessonList
  },
  methods: {
    show() {
      this.$modal.show('vid-modal')
    },
    hide() {
      this.$modal.hide('vid-modal')
    }
  }
}
</script>