<template>
  <v-dropdown-menu class="custom-style">
    <button 
      slot="trigger"
      class="my-1 text-gray-800 hover:text-blue-500 md:mx-4 md:my-0"
    >{{ user.displayName }}</button>
    <div slot="header">Dropdown Header</div>
    <ul slot="body">
      <li>
        <router-link to="/profile">My Profile</router-link>
      </li>
      <li>
        <a href="#" @click="signOut">Logout</a>
      </li>
    </ul>
    <div slot="footer">Dropdown Footer</div>
  </v-dropdown-menu>
</template>

<script>
/*
 * Extending v-dropdown-menu
 * Ref: https://www.npmjs.com/package/v-dropdown-menu
 *      https://github.com/RadKod/v-dropdown-menu
 *      https://v-dropdown-menu.now.sh/
 */
import firebaseApp from '@/firebase-app';
import VDropdownMenu from 'v-dropdown-menu'
import 'v-dropdown-menu/dist/v-dropdown-menu.css'

export default {
  name: 'AccountDropdown',
  components: {
    VDropdownMenu
  },
  methods: {
    async signOut() {
      await firebaseApp.signout()
      // this.$router.push('/')
      this.$router.push('/signin').catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
};
</script>
