<template>
  <section class='relative w-full px-4 py-16 sm:py-24 sm:px-6 xl:px-8 bg-white'>

    <!-- Container -->
    <div class='max-w-xl mx-auto lg:max-w-screen-xl'>
      <div class='lg:grid lg:grid-cols-2 md:gap-16 lg:gap-0'>

        <!-- Section content -->
        <div class='flex flex-col justify-center pr-10 xl:pr-0'>
          <div>
            <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
              Program Plans
            </span>
          </div>
          <h2 class='mt-3.5 sm:mt-4 h2 max-w-xl text-purple-900'>
            A program for every path
          </h2>
          <p class='max-w-lg mt-3 text-lg leading-relaxed text-purple-800'>
            We know you care about your child's future. That’s why we offer you the freedom to choose the right program for them. 
          </p>

          <!-- Contact link -->
          <div class='mt-8 font-medium lg:mt-10'>
            <p class='text-purple-800'>Want to learn more about our programs?</p> 
            <a
              href="/contact.html"
              class="mt-1.5 flex items-center py-0.5 px-0 w-[123px] max-w-full leading-6 text-left text-purple-600 no-underline bg-transparent border-b-2 border-purple-600 border-solid cursor-pointer hover:text-purple-500 transition duration-300 ease-in-out hover:border-purple-400 group"
            >
              <span class="text-base font-bold text-left">Get in touch</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="5" y1="12" x2="19" y2="12" />
                <line x1="15" y1="16" x2="19" y2="12" />
                <line x1="15" y1="8" x2="19" y2="12" />
              </svg>
            </a>
          </div>
        </div>
        <!-- Programs' pricing cards -->
        <div class='grid gap-8 mt-14 lg:mt-20 md:grid-cols-2 lg:gap-4 xl:gap-8'>
          <product-item 
            v-for="(product, index) in products"
            :key="product.id"
            :item="product"
            :index="index"
          ></product-item>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductItem from '@/components/cart/ProductItem.vue'
import ProductsInitMixin from '@/mixins/ProductsInit'

export default {  
  name: 'PricingPartial',
  components: { 
    ProductItem,
  },
  mixins: [
    ProductsInitMixin
  ]
}
</script>