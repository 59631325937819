<template>
  <section class='relative w-full px-4 py-16 sm:py-24 sm:px-6 lg:px-8'>     

    <!-- Container -->
    <div class='max-w-2xl mx-auto lg:max-w-screen-xl'>
      
      <!-- Section header title and subtext  -->
      <div>
        <h2 class='text-purple-900 h2 lg:text-center'>
          Upcoming events
        </h2>
        <p class='max-w-2xl mx-auto mt-3 text-xl leading-relaxed text-purple-800 lg:mt-4 lg:text-center'>
          Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
        </p>
      </div>

      <!-- Events -->
      <div class='mt-12 lg:grid lg:grid-cols-2 lg:gap-6 xl:gap-8 sm:mt-16'>

        <!-- Event 1 -->
        <div class='grid w-full bg-yellow-200 rounded-2xl sm:grid-cols-12'>

          <!-- Event image -->
          <div class='h-48 rounded-t-2xl sm:h-full sm:rounded-tr-none sm:rounded-l-2xl sm:col-span-4'>
            <img src='../../../assets/img/event-01.jpg' class='object-cover object-center w-full h-full rounded-t-2xl sm:rounded-tr-none sm:rounded-l-2xl' alt='Event 01'>
          </div>

          <!-- Event info -->
          <div class='flex flex-col justify-center h-full px-6 py-8 sm:col-span-8 sm:py-10 sm:px-8 lg:px-6 xl:px-8'>
            <div>
              <div class="inline-flex items-center justify-center px-3.5 py-0.5 text-sm -rotate-1 bg-purple-200 text-purple-700 font-medium leading-6 align-top rounded-xl">
                Aug. 14, 2021
              </div>
            </div>
            <h4 class='mt-4 text-2xl font-bold text-purple-900 xl:text-3xl lg:text-2xl sm:text-3xl lg:leading-tight xl:leading-tight'>
              Graduation Ceremony
            </h4>
            <p class='mt-1 text-purple-800 lg:mt-2'>
              Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.
            </p>
          </div>

        </div>


        <!-- Event 2 -->
        <div class='grid w-full mt-8 lg:mt-0 rounded-2xl bg-purple-25 sm:grid-cols-12'>

          <!-- Event info -->
          <div class='flex flex-col justify-center order-2 h-full px-6 py-8 sm:order-1 sm:col-span-8 sm:py-10 sm:px-8 lg:px-6 xl:px-8'>
            <div>
              <div class="inline-flex items-center justify-center px-3.5 py-0.5 text-sm -rotate-1 bg-purple-200 text-purple-700 font-medium leading-6 align-top rounded-xl">
                Jun. 7 - 11, 2021
              </div>
            </div>
            <h4 class='mt-4 text-2xl font-bold text-purple-900 xl:text-3xl lg:text-2xl sm:text-3xl lg:leading-tight xl:leading-tight'>
              Parent Conferences
            </h4>
            <p class='mt-1 text-purple-800 lg:mt-2'>
              Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.
            </p>
          </div>

          <!-- Event image -->
          <div class='order-1 h-48 sm:order-2 rounded-t-2xl sm:h-full sm:rounded-tl-none sm:rounded-r-2xl sm:col-span-4'>
            <img src='../../../assets/img/event-02.jpg' class='object-cover object-center w-full h-full rounded-t-2xl sm:rounded-tl-none sm:rounded-r-2xl' alt='Event 02'>
          </div>

        </div>


        <!-- Event 3 -->
        <div class='grid w-full mt-8 lg:mt-0 rounded-2xl bg-rose-50 sm:grid-cols-12'>

          <!-- Event image -->
          <div class='h-48 rounded-t-2xl sm:h-full sm:rounded-tr-none sm:rounded-l-2xl sm:col-span-4'>
            <img src='../../../assets/img/event-03.jpg' class='object-cover object-center w-full h-full rounded-t-2xl sm:rounded-tr-none sm:rounded-l-2xl' alt='Event 03'>
          </div>

          <!-- Event info -->
          <div class='flex flex-col justify-center h-full px-6 py-8 sm:col-span-8 sm:py-10 sm:px-8 lg:px-6 xl:px-8'>
            <div>
              <div class="inline-flex items-center justify-center px-3.5 py-0.5 text-sm -rotate-1 bg-purple-200 text-purple-700 font-medium leading-6 align-top rounded-xl">
                Jun. 21 - Aug. 13, 2021
              </div>
            </div>
            <h4 class='mt-4 text-2xl font-bold text-purple-900 xl:text-3xl lg:text-2xl sm:text-3xl lg:leading-tight xl:leading-tight'>
              Summer Camp
            </h4>
            <p class='mt-1 text-purple-800 lg:mt-2'>
              Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.
            </p>
          </div>

        </div>


        <!-- Event 4 -->
        <div class='grid w-full mt-8 lg:mt-0 rounded-2xl bg-teal-50 sm:grid-cols-12'>

          <!-- Event info -->
          <div class='flex flex-col justify-center order-2 h-full px-6 py-8 sm:order-1 sm:col-span-8 sm:py-10 sm:px-8 lg:px-6 xl:px-8'>
            <div>
              <div class="inline-flex items-center justify-center px-3.5 py-0.5 text-sm -rotate-1 bg-purple-200 text-purple-700 font-medium leading-6 align-top rounded-xl">
                Aug. 23, 2021
              </div>
            </div>
            <h4 class='mt-4 text-2xl font-bold text-purple-900 xl:text-3xl lg:text-2xl sm:text-3xl lg:leading-tight xl:leading-tight'>
              New School Year
            </h4>
            <p class='mt-1 text-purple-800 lg:mt-2'>
              Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.
            </p>
          </div>

          <!-- Event image -->
          <div class='order-1 h-48 sm:order-2 rounded-t-2xl sm:h-full sm:rounded-tl-none sm:rounded-r-2xl sm:col-span-4'>
            <img src='../../../assets/img/event-04.jpg' class='object-cover object-center w-full h-full rounded-t-2xl sm:rounded-tl-none sm:rounded-r-2xl' alt='Event 04'>
          </div>
          
        </div>
        
      </div>
      
    </div>

  </section>
</template>

<script>
export default {
  name: 'EventsPartial'
}
</script>