<template>
  <div class="w-full">
    <div class="row mt-3">
      <div class="card-error text-danger"></div>
      <!-- <div class="card-success"></div> -->
    </div>
    <div ref="card"></div>
    <button v-on:click="purchase">Purchase</button>
  </div>
</template>

<script>
let stripe = window.Stripe(process.env.VUE_APP_STRIPE_API_KEY)
let card = undefined

let elements = stripe.elements({
  // clientSecret: process.env.VUE_APP_STRIPE_SECRET_KEY,
  fonts: [ {cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap'} ],
})

export default {
  name: 'StripeCardElement',
  methods: {
    purchase: function () {
      let self = this
      stripe.createToken(card).then(function(result) {
        // var successElement = document.querySelector('.card-success');
        var errorElement = document.querySelector('.card-error');

        if (result.token) {
          // successElement.querySelector('.token').textContent = result.token.id;
          self.stripeTokenHandler(result.token.id);
          console.log("Token id: " + result.token.id)
        } 
        else if (result.error) {
          self.hasCardErrors = true;
          errorElement.textContent = result.error.message;
          self.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
          return;
        }

        // console.log(self)
      });
    },
    stripeTokenHandler: function(token) {
      // ar form = document.getElementById('payment-form');
      // var hiddenInput = document.createElement('input');
      // hiddenInput.setAttribute('type', 'hidden');
      // hiddenInput.setAttribute('name', 'user[card_token]');
      // hiddenInput.setAttribute('value', token.id);
      // form.appendChild(hiddenInput);

      console.log(token)
    }
  },
  mounted: function () {
    card = elements.create('card', {style: style});
    card.mount(this.$refs.card);
  },
  head: {
    // script: [
    //   { type: 'text/javascript', src: 'https://js.stripe.com/v3/', async: true, body: true }, // Insert in body
    // ]
  }
}

// Card Element’s Style
let style = {
  base: {
    // border: '1px solid #D8D8D8',
    // borderRadius: '4px',
    
    // iconColor: '#c4f0ff',
    // color: '#fff',
    fontWeight: 500,
    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',

    ':-webkit-autofill': {
      color: '#fce883',
    },
    '::placeholder': {
      color: '#87BBFD',
    },
  },
  // All of the error styles go inside of here.
  invalid: {
    iconColor: '#FFC7EE',
    color: '#FFC7EE',
  }
};
</script>