<template>
  <div class='px-4 sm:px-6'>
    <nav class='flex items-center max-w-screen-xl pt-5 mx-auto'>
      <div class='flex items-center justify-between w-full'>

        <!-- Main navigation menu for large screens -->
        <div class='items-center justify-between hidden lg:flex md:space-x-6 lg:space-x-10'>
          <router-link to="/" exact>
            <div class="relative p-0.5 group">
              <span class="relative z-10 text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600">Home</span>
              <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
            </div>
          </router-link>
          <router-link to="/about">
            <div class="relative p-0.5 group">
              <span class="relative z-10 text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600">About</span>
              <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
            </div>
          </router-link>

          <!-- Programs dropdown container -->
          <dropdown-menu
            :items=programItems
          ></dropdown-menu>

          <router-link to="/lesson">
            <div class="relative p-0.5 group">
              <span class="relative z-10 text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600">Lessons</span>
              <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
            </div>
          </router-link>
          <router-link to="/community">
            <div class="relative p-0.5 group">
              <span class="relative z-10 text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600">Community</span>
              <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
            </div>
          </router-link>
          <router-link to="/contact">
            <div class="relative p-0.5 group">
              <span class="relative z-10 text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600">Contact</span>
              <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
            </div>
          </router-link>
        </div>

        <div class='hidden lg:flex flex-row space-x-2'>
          <locale-menu />
          <cart-indicator />
          <user-menu />
        </div>

        <!-- Logo on smaller screens: < lg -->
        <div class="flex-grow-0 flex-shrink-0 block w-48 lg:hidden sm:w-52">
          <router-link to="/" exact>
            <img src="../../assets/img/bright-logo-6.png" alt="Bright" class="h-auto"/>
          </router-link>
        </div>
      
        <!-- Mobile menu container -->
        <div class='block lg:hidden'>
          <mobile-menu></mobile-menu>          
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import CartIndicator from '@/components/cart/CartIndicator';
import DropdownMenu from '@/components/DropdownMenu';
import MobileMenu from '@/components/MobileMenu';
import UserMenu from '@/components/UserMenu.vue';
import LocaleMenu from '@/components/locale/LocaleMenu.vue';

export default {
  name: "NavBarPartial",
  components: { 
    DropdownMenu,
    MobileMenu,
    CartIndicator,
    UserMenu,
    LocaleMenu
  },
  data() {
    return {
      programItems: [
        { link: '/program', title: 'English Conversation', details: 'Curabitur non nulla sit amet nisl tempu convallis quis ac lectus.' },
        { link: '/program', title: 'IELS Preparation', details: 'Curabitur non nulla sit amet nisl tempu convallis quis ac lectus.' },
        { link: '/program', title: '??? English', details: 'Curabitur non nulla sit amet nisl tempu convallis quis ac lectus.' }
      ]
    }
  }
};
</script>

<style scoped>
  a.active div span,
  a.exact-active div span {
    color: rgba(73, 71, 159, var(--tw-text-opacity));
  }
</style>