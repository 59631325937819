/**
 * Closes a menu when user clicks away from it
 */
export default {
  methods: {
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.state = false
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.close)
  },
  beforeDestroy() {
    document.removeEventListener('click',this.close)
  }
};