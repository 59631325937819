<template>
  <div>
    <!-- Hero -->
    <ContactHeroPartial />
    <!-- Information -->
    <InformationPartial />
    <!-- Height for CtA -->
    <div class='bg-white h-40 sm:h-48'></div>
  </div>
</template>

<script>
import ContactHeroPartial from './partials/contact/ContactHero.vue'
import InformationPartial from './partials/contact/Information.vue'

export default {
  name: 'ContactView',
  components: { 
    ContactHeroPartial,
    InformationPartial 
  },
}
</script>