<template>
  <!--
  <div 
    v-if="isOpen"
    class="w-screen max-w-lg z-100 absolute inset-y-0 right-0 bg-white text-purple-800 flex flex-col shadow-xl overflow-hidden h-full"
  >-->
    <div class="fixed max-w-full flex h-full px-4 sm:px-6">
      <div class="w-screen max-w-md">
        <div class="h-full flex flex-col">
          <div class="flex-1 py-6">
            <!-- Header -->
            <div class="flex items-center justify-between">
              <h1 class="text-2xl font-medium text-purple-800">Shopping cart</h1>
              <div class="flex items-center pr-10">
                <button 
                  @click="closePanel" 
                  class="absolute"
                > <!-- @click="closeCart" -->
                  <x-icon size="44" stroke-width="1" class="text-purple-900" />
                </button>
              </div>
            </div>
            <!-- Body -->
            <main 
              v-if="!cartIsEmpty()"
              class="mt-12 overflow-y-auto h-full"
            >
              <div class="flow-root">
                <ul role="list" class="-my-6 divide-y divide-gray-200">
                  <li 
                    v-for="item in order"
                    :key="item.product.id"
                    class="py-6 flex"
                  >
                    <!-- Product image -->
                    <div class="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                      <img 
                        :src="item.product.images[0]" 
                        :alt="item.product.name" 
                        class="w-full h-full object-center object-cover" 
                      />
                    </div>
                    <!-- Product details -->
                    <div class="ml-3 flex-1 flex flex-col">
                      <div>
                        <div class="flex justify-between text-base font-medium text-purple-800">
                          <h3>
                            {{ item.product.name }}
                          </h3>
                          <p class="ml-4">
                            {{ lineItemPrice(item.price, item.quantity) }}
                          </p>
                        </div>
                        <p class="mt-1 text-sm text-gray-600">
                          {{ item.price.nickname }}
                        </p>
                      </div>
                      <div class="flex-1 flex items-end justify-between text-sm">
                        <p class="text-gray-500">Qty {{ item.quantity }}</p>
                        <div class="flex">
                          <button 
                            @click="removeItem(item.product.id)"
                            type="button"
                            class="font-medium text-blue-700 hover:text-blue-500"
                          >Remove</button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </main>
            <!-- Empty cart! -->
            <div 
              v-else 
              class="flex items-center justify-center px-8 h-full"
            >
              <div class="flex flex-col items-center">
                <!-- https://iconmonstr.com/basket-2-svg/ -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-40 h-auto ">
                  <path d="M4.558 7l4.701-4.702c.199-.198.46-.298.721-.298.613 0 1.02.505 1.02 1.029 0 .25-.092.504-.299.711l-3.26 3.26h-2.883zm12.001 0h2.883l-4.701-4.702c-.199-.198-.46-.298-.721-.298-.613 0-1.02.505-1.02 1.029 0 .25.092.504.299.711l3.26 3.26zm3.703 4l-.016.041-3.598 8.959h-9.296l-3.597-8.961-.016-.039h16.523zm3.738-2h-24v2h.643c.534 0 1.021.304 1.256.784l4.101 10.216h12l4.102-10.214c.233-.481.722-.786 1.256-.786h.642v-2z"/>
                </svg>
                <h3 class="pt-5 font-semibold text-xl">
                  Your cart is currently empty!
                </h3>
                <p class="py-5">
                  Before proceed to checkout, you must add some products to your shopping cart.
                </p>
                <button>button</button>
              </div>
            </div>
          </div>
          <!-- Subtotal and Checkout -->
          <div 
            v-if="!cartIsEmpty()"
            class="border-t border-gray-200 py-6"
          >
            <div class="flex justify-between text-base font-medium text-gray-900">
              <p class="text-lg">Subtotal</p>
              <p class="text-lg">{{ subtotalAmount() }}</p>
            </div>
            <p class="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>
            <div class="mt-6">
              <!-- 
              <router-link
                to="/checkout"
                @click.native.capture="closeCart"
                class="flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700"
              >Checkout</router-link> 
              -->
              <stripe-checkout 
                @click.native.capture="closeCart"
                css="w-full flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700"
              />
            </div>
            <div class="mt-6 flex justify-center text-sm text-center text-gray-500">
              <p>or
                <button 
                  @click="closeCart"
                  type="button" 
                  class="text-blue-600 font-medium hover:text-blue-500" 
                >Continue Shopping<span aria-hidden="true"> &rarr;</span></button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!--
  </div>-->
</template>

<script>
import PriceMixin from '@/mixins/Price'
import CartOrderMixin from '@/mixins/CartOrder'
import StripeCheckout from '@/components/cart/StripeCheckout.vue';
import { XIcon } from "vue-tabler-icons";
import { currencySymbol } from '@/data/currencies'

export default {
  name: 'ShoppingCart',
  components: {
    StripeCheckout,
    XIcon
  },
  methods: {
    currencySymbol,
    /*
     * Returns the line item's price   
     */
    lineItemPrice(price, qty) {
      let str = ""
      str += currencySymbol(price.currency)
      str += this.to2DecimalPlaces( this.calcTotalLinePrice(qty, price.unit_amount) )
      return str
    },
    /**
     * Returns the formatted subtotal
     */
    subtotalAmount() {
      // let str = ""
      // str += currencySymbol(price.currency)
      return this.to2DecimalPlaces(this.subtotal)
    },
    /**
     * 
     */
    cartIsEmpty() {
      return this.order.length === 0
    },
    /* 
     * DEPRECATED 
     * Closes custom slide panel 
     */
    closeCart() {
      this.$store.commit('closeCart');
    },
    /* 
     * @VueSlidePanel
     * Emitting a close panel action
     */
    closePanel() {
      this.$emit('closePanel', {});
    },
    removeItem(id) {
      this.$store.commit('removeItem', id);
    },
  },
  computed: {
    isOpen() {
      return this.$store.state.cartIsOpen;
    }
  },
  mixins: [
    PriceMixin, 
    CartOrderMixin,
  ]
}
</script>