<template>
  <section class='relative px-4 pt-20 sm:pb-0 sm:px-6 lg:px-8 bg-yellow-100'>
    <!-- Container -->
    <div class='max-w-screen-xl mx-auto'>
      <!-- Hero header text -->
      <div class="relative mb-16">
        <div class='flex justify-center'>
          <badge-intro-title title="Latest lessons" />
        </div>
        <h2 class="max-w-3xl mx-auto mt-4 text-center text-purple-900 h1">
          See what the experience looks like
        </h2>
        <p class="max-w-2xl mx-auto mt-4 text-xl leading-relaxed text-center text-purple-800 sm:mt-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aenean et tortor at risus viverra adipiscing at in.
        </p>
      </div>

      <div class='px-4 sm:px-6 lg:px-8'>
        <div class="max-w-3xl mx-auto lg:max-w-screen-xl ">
          <div class='grid gap-y-16 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 lg:gap-x-8'>
            <!-- Video Lesson -->
            <div>
              <div class='aspect-w-3 aspect-h-2'>
                <img src='../../../assets/img//staff-01.jpg' class='object-cover rounded-2xl' alt='Staff #1'>
                <div class='absolute inset-0 flex items-center justify-center'>
                  <span class="absolute inline-flex w-20 h-20 bg-purple-400 rounded-full opacity-60"></span>
        
                  <!-- Video modal button -->
                  <button class="relative z-10 flex items-center justify-center w-20 h-20 bg-white rounded-full outline-none opacity-90" @click="modalOpen = true">
                    <svg class='w-20 h-20 text-purple-600 opacity-95' fill='currentColor' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 485.74 485.74" style="enable-background:new 0 0 485.74 485.74;" xml:space="preserve">
                      <g>
                        <g>
                          <path d="M242.872,0C108.732,0,0.004,108.736,0.004,242.864c0,134.14,108.728,242.876,242.868,242.876    c134.136,0,242.864-108.736,242.864-242.876C485.736,108.736,377.008,0,242.872,0z M338.412,263.94l-134.36,92.732    c-16.776,11.588-30.584,4.248-30.584-16.316V145.38c0-20.556,13.808-27.9,30.584-16.312l134.32,92.732    C355.136,233.384,355.176,252.348,338.412,263.94z"/>
                        </g>
                      </g>
                    </svg>
                  </button>
                  
                </div>
              </div>
              <div class='flex items-center justify-between'>
                <div class='mt-3 text-xl font-medium'>
                  <p class='text-lg text-purple-600'>
                    IELTS | 2 mins.
                  </p>
                  <p class='font-semibold tracking-wide text-purple-900'>
                    
                    <a href="/lesson.html">
                      <div class="relative p-0.5 group">
                        <span class="relative z-10 text-lg font-medium text-purple-700 duration-300 ease-in-out group-hover:text-purple-600">The art of writing</span>
                        <span class="absolute bottom-0 h-1.5 duration-300 ease-in-out origin-bottom transform scale-x-0 bg-yellow-400 rounded-lg -left-1 -right-1 group-hover:scale-x-100"></span>
                      </div>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <!-- Video Lesson -->
            <div>
              <div class='aspect-w-3 aspect-h-2'>
                <img src='../../../assets/img//staff-02.jpg' class='object-cover rounded-2xl' alt='Staff #2'>
              </div>
              <div class='flex items-center justify-between'>
                <div class='mt-3 text-xl font-medium'>
                  <p class='font-semibold tracking-wide text-purple-900'>
                    Andrea Lee
                  </p>
                  <p class='text-lg text-purple-600'>
                    Elementary Director & Lead
                  </p>
                </div>
              </div>
            </div>
            <!-- Video Lesson -->
            <div>
              <div class='aspect-w-3 aspect-h-2'>
                <img src='../../../assets/img//staff-03.jpg' class='object-cover rounded-2xl' alt='Staff #3'>
              </div>
              <div class='flex items-center justify-between'>
                <div class='mt-3 text-xl font-medium'>
                  <p class='font-semibold tracking-wide text-purple-900'>
                    Angela Hill
                  </p>
                  <p class='text-lg text-purple-600'>
                    Preschool Director & Lead
                  </p>
                </div>
              </div>
            </div>
            <!-- Video Lesson -->
            <div>
              <div class='aspect-w-3 aspect-h-2'>
                <img src='../../../assets/img//staff-04.jpg' class='object-cover rounded-2xl' alt='Staff #4'>
              </div>
              <div class='flex items-center justify-between'>
                <div class='mt-3 text-xl font-medium'>
                  <p class='font-semibold tracking-wide text-purple-900'>
                    Viviane Araujo
                  </p>
                  <p class='text-lg text-purple-600'>
                    Preschool Teacher
                  </p>
                </div>
              </div>
            </div>
            <!-- Video Lesson -->
            <div>
              <div class='aspect-w-3 aspect-h-2'>
                <img src='../../../assets/img//staff-05.jpg' class='object-cover rounded-2xl' alt='Staff #5'>
              </div>
              <div class='flex items-center justify-between'>
                <div class='mt-3 text-xl font-medium'>
                  <p class='font-semibold tracking-wide text-purple-900'>
                    Tom Aspinall
                  </p>
                  <p class='text-lg text-purple-600'>
                    Music Teacher
                  </p>
                </div>  
              </div>
            </div>
            <!-- Video Lesson -->
            <div>
              <div class='aspect-w-3 aspect-h-2'>
                <img src='../../../assets/img//staff-06.jpg' class='object-cover rounded-2xl' alt='Staff #6'>
              </div>
              <div class='flex items-center justify-between'>
                <div class='mt-3 text-xl font-medium'>
                  <p class='font-semibold tracking-wide text-purple-900'>
                    Belal Muhammad
                  </p>
                  <p class='text-lg text-purple-600'>
                    Elementary Teacher
                  </p>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-16 flex justify-center">
      <button-more title="See the whole experience" />
    </div>
  </section>
</template>

<script>
import BadgeIntroTitle from '@/views/ui/BadgeIntroTitle.vue'
import ButtonMore from '@/views/ui/ButtonMore.vue'

export default {
  name: 'LatestLessonsPartial',
  components: { 
    ButtonMore, 
    BadgeIntroTitle 
  }
}
</script>