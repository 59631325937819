
import Vue from 'vue'
import Vuex from 'vuex'
import firebaseApp from './firebase-app'
// import products from '@/data/programs.json';
import StripeApi from './api/stripe'

Vue.use(Vuex)

// Locale i18n
const LANG_KEY = 'language'
const initLang = (() => {
  let lang = window.localStorage.getItem(LANG_KEY) || window.navigator.language
  return lang || 'en'
})()

export default new Vuex.Store({
  state: {
    user: null,
    userClaims: null,
    posts: [],
    cart: [], // products (or items) added go here
    cartIsOpen: false,
    items: null, //items: products,
    itemPrices: null,
    lang: initLang,
    currency: 'usd'
  },
  mutations: {
    /*
     * Initializes store properties and ensuring data persistence 
     */
    initStore(state) {
      let savedCurrency = localStorage.getItem('currency')
      if (savedCurrency) {
        state.currency = savedCurrency;
      }
    },
    /*
     * Sets the current authenticated user
     */ 
    setUser(state, payload) {
      state.user = payload
    },
    /*
     * Sets the current authenticated user's claims
     */ 
    setUserClaims(state, payload) {
      state.userClaims = payload
    },
    /*
     * Adds a new post
     */ 
    addPost(state, payload) {
      // Prepopulate data
      let post = {
        id: payload.id,
        data: payload.data()
      }
      state.posts.push(post)
    },
    /*
     * Adds a new item to the shopping cart
     */ 
    ADDITEM_TOCART(state, payload) {
      const product = state.cart.find(
        item => item.productId === payload.productId,
      );
  
      if (product) {
        product.quantity += payload.quantity;
        return;
      }
      state.cart.push({ 
        productId: payload.productId, 
        quantity: payload.quantity, 
        price: payload.price
      });
    },
    /**
     * Removes an item from the cart
     */
     removeItem(state, productId) {
      const indexToRemove = state.cart.findIndex(
        item => item.productId === productId,
      );
      state.cart.splice(indexToRemove, 1);
    },
    /*
     * Controls visibility of cart
     */ 
    openCart(state) {
      state.cartIsOpen = true;
    },
    closeCart(state) {
      state.cartIsOpen = false;
    },
    /*
     * Set products
     */ 
    SET_PRODUCTS(state, products) {
      state.items = products
    },
    /*
     * Set (product) prices
     */ 
    SET_PRICES(state, prices) {
      state.itemPrices = prices
    },
    /*
     * Sets the selected currency
     */
    SET_CURRENCY(state, iso3) {
      localStorage.setItem('currency', iso3);
      state.currency = iso3;
    },
    /**
     * Mutate the change locale action
     */
    onLocaleChanged (state, payload) {
      window.localStorage.setItem(LANG_KEY, payload.lang)
      payload.i18n.locale = payload.lang
      state.lang = payload.lang
    },

    
  },
  actions: {
    /**
     * This is a Stripe API call, requesting all products
     */
    loadProducts( {commit, state } ) {
      // Only load products once!
      if(state.items == null) {
        console.log("Loading products.")
        StripeApi.getProducts()
          .then(response => {
            commit('SET_PRODUCTS', response.data.data)
          })
          .catch( function() {
            commit('SET_PRODUCTS', null);
        });
      }
    },
    /**
     * This is a Stripe API call, requesting all prices
     */
     loadPrices( {commit, state } ) {
      // Only load products once!
      if(state.itemPrices == null) {
        console.log("Loading prices.")
        StripeApi.getPrices()
          .then(response => {
            commit('SET_PRICES', response.data)
            console.log(response)
          })
          .catch( function() {
            commit('SET_PRICES', null);
        });
      }
    },
    /**
     * Load products and (product) prices, then merge them
     */
    loadProductsMergePrices( {commit, state } ) {
      // Only load products once!
      if(state.items == null) {
        console.log("Loading products.")

        StripeApi.getProducts()
          .then(productResponse => {
            let productData = productResponse.data.data
            StripeApi.getPrices().then( priceResponse => { 
              let priceData = priceResponse.data.data 
              // Loop through the products
              for (let i = 0; i < productData.length; i++) {
                // text += prices[i] + "<br>";
                let productId = productData[i].id
                let pricesArr = []
                // Loop through the prices
                for (let x = 0; x < priceData.length; x++) {
                  if(priceData[x].product == productId) {   
                    // Found a match
                    pricesArr.push({
                      id: priceData[x].id,
                      currency: priceData[x].currency,
                      unit_amount: priceData[x].unit_amount,
                      nickname: priceData[x].nickname
                    });
                  }
                }
                // Ad dthe prices array to its product
                productData[i].prices = pricesArr
              } 
            })
            commit('SET_PRODUCTS', productData)
          })
          .catch( function() {
            commit('SET_PRODUCTS', null);
        });
      }
    },

    /**
     * Creates a new post in Firestore
     */
    async createPost({state}, post) {
      let ref = await firebaseApp.database.collection('users').doc(state.user.uid).collection('posts').add(post)      
      console.log(ref)
    },
    /**
     * Update the current locale selction
     */
    changeLocale ({ commit }, payload) {
      commit('onLocaleChanged', payload)
    }
  },
  getters: {
    /*
     * Returns an array of items the end user placed in the cart
     */
    order(state) {
      function createOrderItem(item) {
        return {
          quantity: item.quantity,
          price: item.price,
          product: state.items.find(p => p.id === item.productId),
        };
      }
      return state.cart.map(item => createOrderItem(item));
    },
    /**
     * Calculates the total items (products) in shopping cart
     */
    totalQuantity(state) {
      return state.cart.reduce((previous, current) => previous + current.quantity, 0);
    },
    /**
     * Calculates the sub total of the cart
     */
    subTotal(state) {
      return state.cart.reduce((previous, current) => {
        console.log(state.cart)
        const priceObj = state.cart.find(item => item.productId === current.productId).price;
        const totalPrice = priceObj.unit_amount * current.quantity;
        return previous + totalPrice;
      }, 0);
    },
    /**
     * Return all products
     */
    products(state) {
      return state.items;
    },
    /**
     * Return all prices
     */
    prices(state) {
      return state.itemPrices;
    }
  }
})