<template>
  <span 
    :class="styles"
    class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'
  >
    {{ title }}
  </span>
</template>

<script>
export default {
  name: 'BadgeIntroTitle',
  props: {
    title: {
      type: String
    },
    styles: {
      type: String,
      default: 'bg-purple-200 text-purple-700'
    }
  }
}
</script>