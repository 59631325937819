import axios from 'axios';

const stripe = require('stripe')(`${ process.env.VUE_APP_STRIPE_SECRET_KEY }`);

const apiHeaders = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${ process.env.VUE_APP_STRIPE_SECRET_KEY }`
  }
}

export default {
  /*
   * Returns a list of products 
   */ 
  async getProducts() {
    try {
      return await axios.get(process.env.VUE_APP_STRIPE_PRODUCTS_API, apiHeaders)
    } 
    catch (err) {
      console.error(err);
    }
  },
  /*
   * Returns a list of product prices 
   */ 
  async getPrices() {
    try {
      return await axios.get(process.env.VUE_APP_STRIPE_PRODUCT_PRICES_API, apiHeaders)
    } 
    catch (err) {
      console.error(err);
    }
  },
  /**
   * Returns products and prices within a multiple concurrent request
   */
  getProductsAndPrices() {
    return Promise.all([this.getProducts(), this.getPrices()])
  },
  /**
   * Creates a new Stripe session, the returns the session Id
   */
  async createSession(lineItems) {
    let payload = {
      success_url: `${ process.env.VUE_APP_STRIPE_SUCCESS_URL }?id={CHECKOUT_SESSION_ID}`,
      cancel_url: process.env.VUE_APP_STRIPE_CANCEL_URL,
      payment_method_types: ['card'],
      mode: 'payment',
      line_items: lineItems
    }
    const session = await stripe.checkout.sessions.create(payload);   // Using Stripe's Node.js SDK
    return session.data.id
  },
  /*
    Ref:  https://stripe.com/docs/api/checkout/sessions/create

    Session Endpoints
    -----------------
    POST  /v1/checkout/sessions
    POST  /v1/checkout/sessions/:id/expire
    GET   /v1/checkout/sessions/:id
    GET   /v1/checkout/sessions
    GET   /v1/checkout/sessions/:id/line_items
   */
}