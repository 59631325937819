<template>
  <div>
    <button 
      v-on:click="purchase"
      :class="css"
    >Checkout</button>
  </div>
</template>

<script>
import CartOrderMixin from '@/mixins/CartOrder'

let stripe = window.Stripe(process.env.VUE_APP_STRIPE_API_KEY)

export default {
  props: ['css'],
  data () {
    return {
      publishableKey: process.env.VUE_APP_STRIPE_API_KEY,
      successURL: process.env.VUE_APP_STRIPE_SUCCESS_URL,
      cancelUrl: process.env.VUE_APP_STRIPE_CANCEL_URL
    }
  },
  methods: {
    purchase: function () {
      stripe.redirectToCheckout({
        lineItems: this.lineItems,
        mode: 'payment',
        successUrl: this.successURL,
        cancelUrl: this.cancelUrl,
      }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        if (result.error) {
          console.log(result.error.message)
        }
      });
    }
  },
  computed: {
    /* Line items according to Stripe's Checkout API */
    lineItems() {
      let items = []          // line items
      let order = this.order  // all items in this order
      for (let i = 0; i < order.length; i++) {
        items.push({
          price: order[i].price.id,      // ID of your price (as in Stripe)
          quantity: order[i].quantity
        })
      }
      return items
    }
  },
  mixins: [
    CartOrderMixin
  ]
};
</script>