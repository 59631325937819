<template>
  <div>
    <vue-select
      :data="languages" 
      :current="selectedLang" 
      v-on:valueSelect="onValueSelect"
    >
      <!-- <template v-slot:SelectLabel>Wrestlers</template> -->
    </vue-select> 
  </div>
</template>

<script>
import VueSelect from '@/components/VueSelect.vue'

export default {
  name: 'LanguageSelector',
  components: {
    VueSelect
  },
  methods: {
    /*
    onLocaleChange () {
      this.changeLocale({ 
        lang: this.selectedLang, 
        i18n: this.$i18n 
      })
    },
    */
    
    onValueSelect(selection) {
      this.selectedLang.value = selection.value;
      this.selectedLang.name = selection.name;
      this.selectedLang.img = selection.img;

      this.$i18n.locale = selection.value;
      // this.$store.dispatch("changeLocale");
    }
  },
  computed: {
    // console.log(this.$i18n.locales)
    // this.langs = this.$i18n.locales
  },
  data() {
    return { 
      selectedLang: { value: 'en', name: 'English', img: 'https://flagcdn.com/gb.svg' },
      // Flags API:  https://flagpedia.net/download/api
      languages: [
        { 
          value: 'en', 
          name: 'English', 
          // flag
          img: {
            src: "https://flagcdn.com/gb.svg",
            srcset: ""
          } 
        }, 
        { 
          value: 'ar', 
          name: 'Arabic',  
          // flag
          img: {
            src: "https://flagcdn.com/sa.svg",
            srcset: ""
          } 
        }, 
      ],
    }
  }
}
</script>