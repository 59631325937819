/**
 * Methods and other logic related to RBAC
 */
export default {
  methods: {
    /* Checks is the current user is an Admin */
    isAdmin() {
      return this.claims && this.claims.admin
    },
    /* Checks is the current user is a Teacher */
    isTeacher() {
      return this.claims && this.claims.teacher
    },
    /* Checks is the current user is a Student */
    isStudent() {
      return this.claims && this.claims.student
    }
  },
  computed: {
    claims() {
      return this.$store.state.userClaims
    }
  }
}