<template>
  <div class='hidden px-4 lg:block sm:px-6'>
    <!-- Container -->
    <div class='relative max-w-screen-xl py-5 mx-auto border-b border-purple-200/30'>
      <div class='flex items-center justify-between'>
        <!-- Site branding -->
        <div class="flex-grow-0 flex-shrink-0 w-60">
          <router-link to="/" exact>
            <img src="../../assets/img/bright-logo-6.png" alt="Bright" class="h-auto"/>
          </router-link>
        </div>
        <!-- Contact information -->
        <ul class='flex ml-8 lg:space-x-6 xl:space-x-16'>
          <!-- Address -->
          <li class="flex flex-shrink max-w-xs">
            <div>
              <span class="flex items-center justify-center bg-yellow-400 rounded-2xl w-11 h-11">
                <!-- TablerIcon name: map-pin -->
                <map-pin-icon class="text-purple-700" size="30" stroke-width="1.5" />
              </span>
            </div>
            <div class="flex-1 mt-0 ml-3 xl:ml-4">
              <h5 class="flex items-center text-base font-semibold text-purple-900">
                {{ $t('contact.address') }}
              </h5>
              <p class="mt-0.5 text-sm text-purple-800 leading-relaxed text-opacity-90">
                958 McKinley Avenue Littleton, CO 80120
              </p>
            </div>
          </li>
          <!-- Email -->
          <li class="flex flex-shrink-0">
            <div>
              <span class="flex items-center justify-center bg-purple-200 rounded-2xl w-11 h-11">
                <!-- TablerIcon name: mail -->
                <mail-icon class="text-purple-700" size="30" stroke-width="1.5" />
              </span>
            </div>
            <div class="flex-1 ml-3 xl:ml-4">
              <h5 class="flex items-center text-base font-semibold text-purple-900">
                Email
              </h5>
              <p class="mt-0.5 text-sm text-purple-800 leading-relaxed text-opacity-90">
                me@englishwithshamila.co.uk
              </p>
            </div>
          </li>
          <!-- Phone number -->
          <li class="flex flex-shrink-0">
            <div>
              <span class="flex items-center justify-center rounded-2xl w-11 h-11 bg-rose-200">
                <!-- Icon -->
                <brand-whatsapp-icon class="text-purple-700" size="30" stroke-width="1.5" />
              </span>
            </div>
            <div class="flex-1 ml-3 xl:ml-4">
              <h5 class="flex items-center text-base font-semibold text-purple-900">
                WhatsApp
              </h5>
              <p class="mt-0.5 text-sm text-purple-800 leading-relaxed text-opacity-90">
                +44 7437 822258
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { MapPinIcon, MailIcon, BrandWhatsappIcon } from "vue-tabler-icons";

export default {
  name: 'HeaderPartial',
  components: {
    MailIcon,
    MapPinIcon,
    BrandWhatsappIcon
  }
}
</script>