<template>
  <section class='relative bg-purple-25 w-full px-4 py-16 sm:py-24 lg:py-32 sm:px-6 lg:px-8'> 
    <!-- Container -->
    <div class='max-w-xl mx-auto lg:max-w-screen-xl'>
      <!-- Section header text -->
      <div class='lg:grid lg:grid-cols-2 lg:gap-16 xl:gap-32'>
        <div class='flex items-center'>
          <h2 class='max-w-4xl text-purple-900 h2'>
            A lesson for every kind of learner
          </h2>
        </div>
        <div class='flex items-center mt-6 lg:mt-0'>
          <p class='text-xl leading-relaxed text-purple-800'>
            Sagittis scelerisque nulla cursus in enim consectetur quam. 
            Dictum urna sed consectetur neque tristique pellentesque. 
            Blandit amet, sed aenean erat arcu morbi. 
          </p>
        </div>
      </div>
      <!-- Class info -->
      <div class='grid gap-8 mt-12 sm:max-w-none sm:grid-cols-2 sm:mt-14 lg:mt-24 lg:grid-cols-4 sm:gap-6 xl:gap-12'>
        
        <!-- Info 1 -->
        <div class="w-full pb-10 bg-yellow-200 rounded-xl border border-gray-200">
          <div class="relative">
            <div class="relative w-full py-3 h-full">

              <div class='relative'>
                <img src="https://img.youtube.com/vi/2ly2il11JaU/maxresdefault.jpg" alt="">
                <div class='absolute inset-0 flex items-center justify-center'>
                  <span class="absolute inline-flex w-11 h-11 bg-yellow-100 rounded-full opacity-60"></span>
                  <!-- Video modal button -->
                  <button 
                    @click="setSrc('2ly2il11JaU'); show()"
                    class="relative z-10 flex items-center justify-center w-10 h-10 bg-white rounded-full outline-none opacity-90"
                  > <!-- @click="showModal = true" -->
                    <svg class='w-10 h-10 text-gray-600 opacity-95' fill='currentColor' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 485.74 485.74" style="enable-background:new 0 0 485.74 485.74;" xml:space="preserve">
                      <g>
                        <g>
                          <path d="M242.872,0C108.732,0,0.004,108.736,0.004,242.864c0,134.14,108.728,242.876,242.868,242.876    c134.136,0,242.864-108.736,242.864-242.876C485.736,108.736,377.008,0,242.872,0z M338.412,263.94l-134.36,92.732    c-16.776,11.588-30.584,4.248-30.584-16.316V145.38c0-20.556,13.808-27.9,30.584-16.312l134.32,92.732    C355.136,233.384,355.176,252.348,338.412,263.94z"/>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
              
            </div>
            <div class="px-4 lg:px-4 xl:px-6 pb-4">
              <div class='mt-2'>
                <div class='mt-3'>
                  <div class="inline-block h-6 px-3 text-xs font-medium leading-6 text-purple-700 align-top bg-yellow-400 -rotate-1 rounded-xl uppercase">
                    IELTS
                  </div>
                </div>
                <h4 class='block mt-4 text-xl font-semibold text-purple-700'>
                  Writing tips
                </h4>
                <div class='w-8 h-1.5 bg-gradient-to-r from-yellow-400 to-yellow-500 my-2 rounded-2xl'></div>
              </div>
              <!-- Features -->
              <ul class='mt-4 space-y-2 text-base'>
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span class="ml-2 text-purple-800">
                    Nulla quis lorem ut libero
                  </span>
                </li>
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span class="ml-2 text-purple-800">
                    Lorem ipsum dolor sit
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Info 2 -->
        <div class="w-full pb-10 bg-purple-50 rounded-xl border border-gray-200">
          <div class="relative">
            <div class="relative w-full py-3 h-full">

              <div class='relative'>
                <img src="https://img.youtube.com/vi/ZOLCMa2QbdE/maxresdefault.jpg" alt="">
                <div class='absolute inset-0 flex items-center justify-center'>
                  <span class="absolute inline-flex w-11 h-11 bg-purple-200 rounded-full opacity-60"></span>
                  <!-- Video modal button -->
                  <button 
                    @click="setSrc('ZOLCMa2QbdE'); show()"
                    class="relative z-10 flex items-center justify-center w-10 h-10 bg-white rounded-full outline-none opacity-90"
                  > <!-- @click="showModal = true" -->
                    <svg class='w-10 h-10 text-gray-600 opacity-95' fill='currentColor' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 485.74 485.74" style="enable-background:new 0 0 485.74 485.74;" xml:space="preserve">
                      <g>
                        <g>
                          <path d="M242.872,0C108.732,0,0.004,108.736,0.004,242.864c0,134.14,108.728,242.876,242.868,242.876    c134.136,0,242.864-108.736,242.864-242.876C485.736,108.736,377.008,0,242.872,0z M338.412,263.94l-134.36,92.732    c-16.776,11.588-30.584,4.248-30.584-16.316V145.38c0-20.556,13.808-27.9,30.584-16.312l134.32,92.732    C355.136,233.384,355.176,252.348,338.412,263.94z"/>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
              
            </div>
            <div class="px-4 lg:px-4 xl:px-6 pb-4">
              <div class='mt-2'>
                <div class='mt-3'>
                  <div class="inline-block h-6 px-3 text-xs font-medium leading-6 text-purple-700 align-top bg-purple-200 -rotate-1 rounded-xl uppercase">
                    Academic English
                  </div>
                </div>
                <h4 class='block mt-4 text-xl font-semibold text-purple-700'>
                  Job Interview English
                </h4>
                <div class='w-8 h-1.5 bg-gradient-to-r from-purple-200 to-purple-300 my-2 rounded-2xl'></div>
              </div>
              <!-- Features -->
              <ul class='mt-4 space-y-2 text-base'>
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span class="ml-2 text-purple-800">
                    Nulla quis lorem ut libero
                  </span>
                </li>
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span class="ml-2 text-purple-800">
                    Lorem ipsum dolor sit
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Info 3 -->
        <div class="w-full pb-10 bg-rose-50 rounded-xl border border-gray-200">
          <div class="relative">
            <div class="relative w-full py-3 h-full">

              <div class='relative'>
                <img src="https://img.youtube.com/vi/jy88ejZrPWw/maxresdefault.jpg" alt="">
                <div class='absolute inset-0 flex items-center justify-center'>
                  <span class="absolute inline-flex w-11 h-11 bg-rose-200 rounded-full opacity-60"></span>
                  <!-- Video modal button -->
                  <button 
                    @click="setSrc('jy88ejZrPWw'); show()"
                    class="relative z-10 flex items-center justify-center w-10 h-10 bg-white rounded-full outline-none opacity-90"
                  > <!-- @click="showModal = true" -->
                    <svg class='w-10 h-10 text-gray-600 opacity-95' fill='currentColor' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 485.74 485.74" style="enable-background:new 0 0 485.74 485.74;" xml:space="preserve">
                      <g>
                        <g>
                          <path d="M242.872,0C108.732,0,0.004,108.736,0.004,242.864c0,134.14,108.728,242.876,242.868,242.876    c134.136,0,242.864-108.736,242.864-242.876C485.736,108.736,377.008,0,242.872,0z M338.412,263.94l-134.36,92.732    c-16.776,11.588-30.584,4.248-30.584-16.316V145.38c0-20.556,13.808-27.9,30.584-16.312l134.32,92.732    C355.136,233.384,355.176,252.348,338.412,263.94z"/>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
              
            </div>
            <div class="px-4 lg:px-4 xl:px-6 pb-4">
              <div class='mt-2'>
                <div class='mt-3'>
                  <div class="inline-block h-6 px-3 text-xs font-medium leading-6 text-purple-700 align-top bg-rose-200 -rotate-1 rounded-xl uppercase">
                    Academic English
                  </div>
                </div>
                <h4 class='block mt-4 text-xl font-semibold text-purple-700'>
                  At the airport
                </h4>
                <div class='w-8 h-1.5 bg-gradient-to-r from-rose-100 to-rose-300 my-2 rounded-2xl'></div>
              </div>
              <!-- Features -->
              <ul class='mt-4 space-y-2 text-base'>
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span class="ml-2 text-purple-800">
                    Nulla quis lorem ut libero
                  </span>
                </li>
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span class="ml-2 text-purple-800">
                    Lorem ipsum dolor sit
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Info 4 -->
        <div class="w-full pb-10 bg-blue-50 rounded-xl border border-gray-200">
          <div class="relative">
            <div class="relative w-full py-3 h-full">

              <div class='relative'>
                <img src="https://img.youtube.com/vi/7mmBdUc0ZMA/maxresdefault.jpg" alt="">
                <div class='absolute inset-0 flex items-center justify-center'>
                  <span class="absolute inline-flex w-11 h-11 bg-blue-200 rounded-full opacity-60"></span>
                  <!-- Video modal button -->
                  <button 
                    @click="setSrc('7mmBdUc0ZMA'); show()"
                    class="relative z-10 flex items-center justify-center w-10 h-10 bg-white rounded-full outline-none opacity-90"
                  >
                    <svg class='w-10 h-10 text-gray-600 opacity-95' fill='currentColor' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 485.74 485.74" style="enable-background:new 0 0 485.74 485.74;" xml:space="preserve">
                      <g>
                        <g>
                          <path d="M242.872,0C108.732,0,0.004,108.736,0.004,242.864c0,134.14,108.728,242.876,242.868,242.876    c134.136,0,242.864-108.736,242.864-242.876C485.736,108.736,377.008,0,242.872,0z M338.412,263.94l-134.36,92.732    c-16.776,11.588-30.584,4.248-30.584-16.316V145.38c0-20.556,13.808-27.9,30.584-16.312l134.32,92.732    C355.136,233.384,355.176,252.348,338.412,263.94z"/>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="px-4 lg:px-4 xl:px-6 pb-4">
              <div class='mt-2'>
                <div class='mt-3'>
                  <div class="inline-block h-6 px-3 text-xs font-medium leading-6 text-purple-700 align-top bg-blue-200 -rotate-1 rounded-xl uppercase">
                    Conversation English
                  </div>
                </div>
                <h4 class='block mt-4 text-xl font-semibold text-purple-700'>
                  Speak with me
                </h4>
                <div class='w-8 h-1.5 bg-gradient-to-r from-blue-100 to-blue-300 my-2 rounded-2xl'></div>
              </div>
              <!-- Features -->
              <ul class='mt-4 space-y-2 text-base'>
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span class="ml-2 text-purple-800">
                    Nulla quis lorem ut libero
                  </span>
                </li>
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span class="ml-2 text-purple-800">
                    Lorem ipsum dolor sit
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
        <!-- Modal -->
        <modal 
          name="vid-modal"
          :resizable="resizable" 
          :adaptive="adaptive" 
          :draggable="draggable"
        >
          <iframe
            title="Video"
            class="absolute w-full h-full" 
            :src="videoSrc" 
            webkitAllowFullScreen mozallowfullscreen allowFullScreen
          ></iframe>
        </modal>
  </section>
</template>

<script>
export default {
  name: "LessonList",
  data() {
    return {
      videoSrc: "",
      resizable: true,
      adaptive: true,
      draggable: true
    }
  },
  methods: {
    // Open modal window
    show() {
      this.$modal.show('vid-modal')
    },
    // Close modal window
    hide() {
      this.$modal.hide('vid-modal')
    },
    setSrc(code) {
      this.videoSrc = "https://www.youtube.com/embed/" + code
    }
  }
}
</script>