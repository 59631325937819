<template>
  <div class="first-letter:flex relative flex-col space-x-0.5 justify-center h-full">
    <button 
      @click="toggle" 
      class="self-center"
    >
      <user-icon 
        stroke-width="1"
        class="p-0.5 w-10 h-10 text-purple-800 self-center rounded-2xl border border-gray-300 shadow-sm bg-transparent 
              focus:outline-none focus:shadow-outline-purple focus:border-2 active:border-2 focus:bg-white" 
      />
    </button>
      <!-- Menu -->
      <div 
        v-show="state" 
        class="absolute right-0 z-100 bg-white rounded overflow-hidden shadow-lg"
      >
        <!-- User logged in -->
        <div v-if="user">
          <div class="p-5 border-b">
            <div class="flex justify-between items-center">
              <img class="h-12 w-12 rounded-full" src="https://randomuser.me/api/portraits/men/24.jpg" alt="">
              <div class="ml-2 pr-12">
                <h3 class="text-lg text-gray-800 font-semibold">{{ user.displayName }}</h3>
                <div class="text-gray-600 text-sm">{{ user.email }}</div>
              </div>
            </div>
            <div class="mt-5 text-center w-full">
              <router-link
                to="/profile"
                class="w-full border rounded-full py-2 px-4 text-xs font-semibold text-gray-700"
              >Manage your Account</router-link>
            </div>
          </div>
          <!-- Account & Personal settings -->
          <div class="border-b my-2">
            <a href="#" class="px-5 py-2 hover:bg-gray-100 flex">
              <div class="text-gray-800 flex self-center">
                <settings-icon stroke-width="1" class="w-6 h-6" />
              </div>
              <div class="pl-3">
                <p class="text-sm font-medium text-gray-800 leading-none">Account settings</p>
                <p class="text-xs text-gray-500">Usage, billing, branding, teams</p>
              </div>
            </a>
            <a href="#" class="px-5 py-2 mb-2 hover:bg-gray-100 flex">
              <div class="text-gray-800 flex self-center">
                <id-icon stroke-width="1" class="w-6 h-6" />
              </div>
              <div class="pl-3">
                <p class="text-sm font-medium text-gray-800 leading-none">Personal settings</p>
                <p class="text-xs text-gray-500">Email, profile, notifications</p>
              </div>
            </a>
          </div>
          <!-- Links -->
          <div class="mb-2">
            <a href="#" class="px-6 py-2 pb-4 hover:bg-gray-100 flex">
              <p class="text-sm font-medium text-gray-800 leading-none">Support</p>
            </a>
            <a 
              @click="signOut"
              href="#" 
              class="px-6 py-2 pb-4 hover:bg-gray-100 flex"
            >
              <p class="text-sm font-medium text-gray-800 leading-none">Logout</p>
            </a>
          </div>
        </div>

        <!-- User not logged in -->
      <div v-else>
        <div class="my-2 w-full">
          <!-- Sign in -->
          <router-link
            to="/signin"
            class="w-full px-5 py-1 mb-2 hover:bg-gray-100 flex items-center"
          >
            <div class="text-gray-800">
              <login-icon stroke-width="1" class="w-6 h-6" />
            </div>
            <div class="pl-2">
              <p class="text-sm font-medium text-gray-800 leading-none">Login</p>
            </div>
          </router-link>
          <!-- Sign up -->
          <router-link
            to="/signup"
            class="w-full px-5 py-1 mb-2 hover:bg-gray-100 flex items-center"
          >
            <div class="text-gray-800">
              <file-invoice-icon stroke-width="1" class="w-6 h-6" />
            </div>
            <div class="pl-2 w-full">
              <p class="text-sm font-medium text-gray-800 leading-none">Register</p>
            </div>
          </router-link>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
import ClickAwayMixin from '@/mixins/ClickAway'
import firebaseApp from '@/firebase-app';
import { UserIcon, IdIcon, LoginIcon, FileInvoiceIcon, SettingsIcon } from "vue-tabler-icons";

export default {
  name: 'UserMenu',
  components: {
    UserIcon,
    IdIcon,
    LoginIcon,
    FileInvoiceIcon,
    SettingsIcon
  },
  methods: {
    /* Toggles the menu */ 
    toggle() {
      this.state = !this.state;
    },
    /* Signs the user out */ 
    async signOut() {
      await firebaseApp.signout()
      // this.$router.push('/')
      this.$router.push(this.$route.name).catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mixins: [ClickAwayMixin],
  data() {
    return {
      state: false
    };
  }
}
</script>

<style>
.btn {
  width: 182px;
  font-size: 14px;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  padding: 8px;
}
</style>