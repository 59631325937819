<template>
  <div class='bg-gradient-to-b from-purple-25 to-purple-50'>
    <header>
      <HeaderPartial />
      <NavbarPartial />
    </header>
    <!-- <shopping-cart /> -->

    <slideout-panel />

    <slot />
    <FooterPartial />
  </div>
</template>

<script>
import HeaderPartial from "../views/partials/Header.vue";
import NavbarPartial from "../views/partials/NavBar.vue";
import FooterPartial from '../views/partials/Footer.vue';
// import ShoppingCart from '../components/cart/ShoppingCart.vue';

export default {
  name: 'DefaultLayout',
  components: {
    HeaderPartial,
    NavbarPartial,
    FooterPartial,
    // ShoppingCart,
  }
}
</script>

